const en_messages = {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    languages: "Languages",
    agentList: "Agent list",
    addAgent: "Add agent",
    agentsMenu: "Agents",
    quotationMenu: "Quotation",
    projectsMenu: "Projects",
    Projects: "Projects",
    calendarApp: "Calendar",
    menuTreeListing: "Tree menu",
    treeListing: "Tree",
    //Login index view texts
    "FEEDBACK_UNKNOWN_ERROR": "Unknown error occurred!",
    "FEEDBACK_DELETED": "Your account is not yet active. Please contact the webmaster for information.",
    "FEEDBACK_ACCOUNT_SUSPENDED": "Account suspended for ",
    "FEEDBACK_ACCOUNT_SUSPENSION_DELETION_STATUS": "This user's suspension/deletion settings have been successfully modified.",
    "FEEDBACK_ACCOUNT_CANT_DELETE_SUSPEND_OWN": "You cannot delete or suspend your own account.",
    "FEEDBACK_ACCOUNT_USER_SUCCESSFULLY_KICKED": "The selected user has been successfully kicked out of the system (by resetting this user's session)",
    "FEEDBACK_PASSWORD_WRONG_3_TIMES": "You have typed a wrong password 3 or more times already. Please wait 30 seconds to try again.",
    "FEEDBACK_ACCOUNT_NOT_ACTIVATED_YET": "Your account is not active yet. Please click on the confirm link in the mail.",
    "FEEDBACK_USERNAME_OR_PASSWORD_WRONG": "The username or password is incorrect. Please try again.",
    "FEEDBACK_USER_DOES_NOT_EXIST": "This user does not exist.",
    "FEEDBACK_AZIENDA_DOES_NOT_EXIST": "This company does not exist",
    "FEEDBACK_LOGIN_FAILED": "Login failed.",
    "FEEDBACK_LOGIN_FAILED_3_TIMES": "Login failed 3 or more times already. Please wait 30 seconds to try again.",
    "FEEDBACK_USERNAME_FIELD_EMPTY": "Username field is empty.",
    "FEEDBACK_PASSWORD_FIELD_EMPTY": "Password field is empty.",
    "FEEDBACK_USERNAME_OR_PASSWORD_FIELD_EMPTY": "Username or password field is empty.",
    "FEEDBACK_USERNAME_EMAIL_FIELD_EMPTY": "Username / email field is empty.",
    "FEEDBACK_EMAIL_FIELD_EMPTY": "Email field is empty.",
    "FEEDBACK_EMAIL_REPEAT_WRONG": "Email and repeat email fields are not the same",
    "FEEDBACK_EMAIL_AND_PASSWORD_FIELDS_EMPTY": "Email and password fields are empty.",
    "FEEDBACK_USERNAME_SAME_AS_OLD_ONE": "Sorry, the username you chose is the same as your current one. Please choose another one.",
    "FEEDBACK_USERNAME_ALREADY_TAKEN": "Sorry, the username you chose has already been selected. Please choose another one.",
    "FEEDBACK_USER_EMAIL_ALREADY_TAKEN": "Sorry, the email you chose is already in use. Please choose another one.",
    "FEEDBACK_USERNAME_CHANGE_SUCCESSFUL": "Your username has been changed successfully.",
    "FEEDBACK_USERNAME_AND_PASSWORD_FIELD_EMPTY": "Username and password fields were empty.",
    "FEEDBACK_USERNAME_DOES_NOT_FIT_PATTERN": "Username does not fit the name pattern: only a-Z and numbers are allowed, 2 to 64 characters.",
    "FEEDBACK_EMAIL_DOES_NOT_FIT_PATTERN": "Sorry, the email address you chose does not fit the email naming pattern.",
    "FEEDBACK_EMAIL_SAME_AS_OLD_ONE": "Sorry, that email address you chose is the same as your current one. Please choose another one.",
    "FEEDBACK_EMAIL_CHANGE_SUCCESSFUL": "Your email address has been changed successfully.",
    "FEEDBACK_CAPTCHA_WRONG": "The entered captcha security characters are wrong.",
    "FEEDBACK_PASSWORD_REPEAT_WRONG": "Password and repeat password are not the same.",
    "FEEDBACK_PASSWORD_TOO_SHORT": "Password must have a minimum length of 6 characters.",
    "FEEDBACK_USERNAME_TOO_SHORT_OR_TOO_LONG": "Username cannot be shorter than 2 or longer than 64 characters.",
    "FEEDBACK_ACCOUNT_SUCCESSFULLY_CREATED": "Your account has been created successfully and we have sent you an email. Please click the VERIFICATION LINK within that mail.",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_FAILED": "Sorry, we could not send you a verification mail. Your account has NOT been created. Please contact the support team.",
    "FEEDBACK_ACCOUNT_CREATION_FAILED": "Sorry, your registration failed. Please go back and try again.",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_ERROR": "Verification mail could not be sent due to: ",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_SUCCESSFUL": "A verification mail has been sent successfully.",
    "FEEDBACK_ACCOUNT_ACTIVATION_SUCCESSFUL": "Your email address has been correctly verified.<br> Please wait until the admin approves your registration.",
    "FEEDBACK_ADMIN_APPROVAL_MAIL_SENDING_SUCCESSFUL": "The administrator has received the notification about your request <br> You will receive a confirmation email immediately after the approval.<br>This passage will require less than 24h",
    "FEEDBACK_ACCOUNT_ACTIVATION_FAILED": "Sorry, the id/verification code combination doesn't exist! It might be possible that your mail provider automatically visits links in emails for anti-scam scanning, so this activation link might have been clicked without your action. Please try to log in on the main page.",
    "FEEDBACK_AVATAR_UPLOAD_SUCCESSFUL": "Your avatar has been uploaded successfully.",
    "FEEDBACK_AVATAR_UPLOAD_WRONG_TYPE": "Only JPEG and PNG files are supported.",
    "FEEDBACK_AVATAR_UPLOAD_TOO_SMALL": "Avatar source file's width/height is too small. Needs to be 100x100 pixel minimum.",
    "FEEDBACK_AVATAR_UPLOAD_TOO_BIG": "Avatar source file is too big. 5 Megabyte is the maximum.",
    "FEEDBACK_AVATAR_FOLDER_DOES_NOT_EXIST_OR_NOT_WRITABLE": "Avatar folder does not exist or is not writable.",
    "FEEDBACK_AVATAR_IMAGE_UPLOAD_FAILED": "Something went wrong with the image upload.",
    "FEEDBACK_AVATAR_IMAGE_DELETE_SUCCESSFUL": "You successfully deleted your avatar.",
    "FEEDBACK_AVATAR_IMAGE_DELETE_NO_FILE": "You don't have a custom avatar yet.",
    "FEEDBACK_AVATAR_IMAGE_DELETE_FAILED": "Something went wrong while deleting your avatar.",
    "FEEDBACK_PASSWORD_RESET_TOKEN_FAIL": "Could not write token to database.",
    "FEEDBACK_PASSWORD_RESET_TOKEN_MISSING": "No password reset token.",
    "FEEDBACK_PASSWORD_RESET_MAIL_SENDING_ERROR": "Password reset mail could not be sent due to: ",
    "FEEDBACK_PASSWORD_RESET_MAIL_SENDING_SUCCESSFUL": "A password reset mail has been sent successfully.",
    "FEEDBACK_PASSWORD_RESET_LINK_EXPIRED": "Your reset link has expired. Please use the reset link within one hour.",
    "FEEDBACK_PASSWORD_RESET_COMBINATION_DOES_NOT_EXIST": "Username/Verification code combination does not exist.",
    "FEEDBACK_PASSWORD_RESET_LINK_VALID": "Password reset validation link is valid. Please change the password now.",
    "FEEDBACK_EMAIL_CHANGE_FAILED": "Sorry, your email changing failed. Please contact our support team.",
    "FEEDBACK_PASSWORD_CHANGE_SUCCESSFUL": "Password successfully changed.",
    "FEEDBACK_PASSWORD_CHANGE_FAILED": "Sorry, your password changing failed. Please contact our support team.",
    "FEEDBACK_PASSWORD_NEW_SAME_AS_CURRENT": "New password is the same as the current password.",
    "FEEDBACK_PASSWORD_CURRENT_INCORRECT": "Current password entered is incorrect.",
    "FEEDBACK_ACCOUNT_TYPE_CHANGE_SUCCESSFUL": "Account type change successful",
    "FEEDBACK_ACCOUNT_TYPE_CHANGE_FAILED": "Account type change failed",
    "FEEDBACK_NOTE_CREATION_FAILED": "Note creation failed.",
    "FEEDBACK_NOTE_EDITING_FAILED": "Note editing failed.",
    "FEEDBACK_NOTE_DELETION_FAILED": "Note deletion failed.",
    "FEEDBACK_COOKIE_INVALID": "Your remember-me-cookie is invalid.",
    "FEEDBACK_COOKIE_LOGIN_SUCCESSFUL": "You were successfully logged via the remember-me-cookie.",
    "FEEDBACK_COOKIE_COMPANY_EDIT_SUCCESSFUL":"The company profile was updated successfully",
    "FEEDBACK_ERROR_WHILE_GETTING_SETTORI_LIST": "An error occurred while trying to recover the industrial sector list",
    "FEEDBACK_ERROR_WHILE_GETTING_FATTURATI_LIST": "An error occurred while trying to recover the revenue list",
    "FEEDBACK_AZIENDA_CORRECTLY_MODIFIED": "The operation on the company details was correctly performed",
    "FEEDBACK_USER_EMAIL_CORRECTLY_SENT": "A notification mail has been successfully sent to the user",
    "FEEDBACK_USER_EMAIL_NOT_SENT": "Unknown mail delivery subsystem error: the user may not have been notified about the operation",
    "FEEDBACK_DEADLINE_SET_TO_PAST": "Impossible to perform the operation: the chosen date is not in the future",
    "FEEDBACK_DEADLINE_SET_AFTER_LIMIT": "Date overflow reached. The system supports dates until 31/12/2030",
    "FEEDBACK_GENERIC_OPERATION_ERROR": "Impossible to perform the operation correctly. Please verify the input format correctness",
    "FEEDBACK_GENERIC_OPERATION_SUCCESS": "The operation was performed successfully",
    "FEEDBACK_GENERIC_DB_ERROR": "Connection error with the database. Please contact the website support team",
    "FEEDBACK_SURVEY_SET_CORRECTLY_SAVED": "The values inserted into the previous set were correctly saved",
    "FEEDBACK_SURVEY_SAVING_ERROR": "Error while saving the new value. Please contact the website support team",
    "FEEDBACK_SURVEY_CORRECTLY_ENDED": "The survey was correctly completed. Thanks for the collaboration",
    "FEEDBACK_AZIENDA_NOT_YET_APPROVED": "Your account has not yet been approved by the administration. Please wait until the confirmation mail",
    "FEEDBACK_CONCURRENT_SESSION_FORCED_LOGOUT": "Your are already logged in your account. Please logout from other sessions before attempting again.",
    "FEEDBACK_SECTOR_MODIFICATION_SUCCESS": "The industrial sector of your company has been correctly modified",
    "FEEDBACK_SECTOR_MODIFICATION_ERROR": "Error while attempting to modify your company industrial sector. Please try again",
    "FEEDBACK_FATTURATO_MODIFICATION_SUCCESS": "The revenue range of your company has been correctly modified",
    "FEEDBACK_FATTURATO_MODIFICATION_ERROR": "Error while attempting to modify your company revenue range. Please try again",
    "FEEDBACK_EMPLOYEES_RANGE_MODIFICATION_SUCCESS": "The employees range of your company has been correctly modified",
    "FEEDBACK_EMPLOYEES_RANGE_MODIFICATION_ERROR": "Error while attempting to modify your company employees range. Please try again",
    "FEEDBACK_ASSOCIATED_STATUS_MODIFICATION_SUCCESS": "The associative status of your company has been correctly modified",
    "FEEDBACK_ASSOCIATED_STATUS_MODIFICATION_ERROR": "Error while attempting to modify your company associative status. Please try again",
    "FEEDBACK_QUOTED_STATUS_MODIFICATION_SUCCESS": "The listing status of your company has been correctly modified",
    "FEEDBACK_QUOTED_STATUS_MODIFICATION_ERROR": "Error while attempting to modify your company listing status. Please try again",
    "FEEDBACK_DENOM_MODIFICATION_SUCCESS": "The name of your company has been correctly modified",
    "FEEDBACK_DENOM_MODIFICATION_ERROR": "Error while attempting to modify your company name. Please try again",
    "REGISTRATION_MAIN_HEADER": "Request a new account for the service!",
    "REGISTRATION_P_IVA_PLACEHOLDER": "VAT number (11 digits)",
    "REGISTRATION_DENOMINATION_PLACEHOLDER": "Legal entity",
    "REGISTRATION_INDUSTRIAL_SECTOR_MESSAGE": "Please select your industrial sector",
    "REGISTRATION_INDUSTRIAL_FOOD_AND_BEVERAGE": "Food / Beverage / Consumer Goods",
    "REGISTRATION_REVENUE_RANGE_MESSAGE": "Please select your revenue range",
    "REGISTRATION_EMPLOYEE_RANGE_MESSAGE": "Please select your employee range",
    "REGISTRATION_USERNAME": "Username",
    "REGISTRATION_USERNAME_PLACEHOLDER": "Insert from 2 to  64 characters in 0-9 a-z A-Z  _ (spaces not allowed)",
    "REGISTRATION_EMAIL": "Enter your email address",
    "REGISTRATION_EMAIL_PLACEHOLDER": "Insert a valid email address",
    "REGISTRATION_EMAIL_REPEAT_PLACEHOLDER": "Repeat the email address",
    "REGISTRATION_PASSWORD_PLACEHOLDER": "Insert at least 6 characters",
    "REGISTRATION_PASSWORD_REPEAT_PLACEHOLDER": "Please repeat the password",
    "REGISTRATION_ROLE_PLACEHOLDER": "Specify your business title",
    "REGISTRATION_PHONE": "Leave us a telephone number",
    "REGISTRATION_PHONE_PLACEHOLDER": "Insert only numeric digits",
    "REGISTRATION_CAPTCHA_PLACEHOLDER": "Please insert the characters above",
    "REGISTRATION_RELOAD_CAPTCHA": "Reload captcha code",
    "REGISTRATION_SUBMIT_BUTTON": "Request an account",
    "TRY_AGAIN_SUBMIT_BUTTON": "Try again",
    "REGISTRATION_OTHER_SECTOR_PLACEHOLDER": "Describe your industrial sector (max 128 characters)",
    "REGISTRATION_P_IVA_INVALID": "Insert exactly 11 numeric digits",
    "REGISTRATION_DENOM_INVALID": "Insert max 128 characters  ( \", &, ', <, > not allowed)",
    "REGISTRATION_USERNAME_INVALID": "Max 64 characters. Allowed 0-9 a-z A-Z  _ (spaces not allowed)",
    "REGISTRATION_EMAIL_INVALID": "Please insert a valid email format",
    "REGISTRATION_PASSWORD_INVALID": "The password must contain at least 6 characters",
    "REGISTRATION_EMAIL_REPEAT_NOT_MATCHED": "The email fields do not match",
    "REGISTRATION_PASSWORD_REPEAT_NOT_MATCHED": "The password fields do not match",
    "REGISTRATION_ROLE_INVALID": "Insert max 64 characters in a-z A-Z (spaces allowed)",
    "REGISTRATION_PHONE_INVALID": "The phone field must contain 8-10 digits without spaces or other characters",
    "REGISTRATION_CONFIRM_FEEDBACK_HEADER": "Verify your registration data",
    "REGISTRATION_CONFIRM_FEEDBACK_BACK_TO_HOME": "Go back to homepage",
    "REGISTRATION_ASSOCIATED_QUESTION": "Is your company a member of Valore D?",
    "REGISTRATION_QUOTED_QUESTION": "Is your company listed on the stock exchange?",
    "REGISTRATION_FINANCIAL_YEAR_CLOSURE_DATE_MESSAGE": "Select the closing date of your fiscal year (default: 31/12)",
    "REGISTRATION_DATE_PICKER_INVALID": "Invalid date format (insert a DD MMMM string)",
    "REGISTRATION_AZIENDA_IS_ASSOCIATED_LABEL":"Membership status",
    "REGISTRATION_AZIENDA_IS_QUOTED_LABEL":"Listing status",
    "REGISTRATION_COMPANY_TYPE_LABEL":"Company type",
    "REGISTRATION_CHANGE_PMI_OPTION": "Small-medium italian company (up to 250 employees, <= 50 milion euros of annual revenue)",
    "LOGIN_HEADER": "Login Here",
    "LOGIN_USERNAME_EMAIL_PLACEHOLDER": "Username or email",
    "LOGIN_REMEMBER_ME": "Remember for two weeks",
    "LOGIN_BUTTON": "LOGIN",
    "LOGIN_LABEL": "Login",
    "LOGIN_ACCESS_TOOL": "Access the tool",
    "LOGIN_EDIT_REGISTRATION_INFO": "Please enter your registration details and wait for confirmation by e-mail.",
    "REGISTER_LABEL": "Register",
    "OR_LABEL": "Or",
    "LOGIN_FORGOT_PSW_LINK": "I forgot my password",
    "LOGIN_NO_ACCOUNT_MESSAGE": "Don't have an account yet?",
    "LOGIN_REGISTER_LINK": "Request registration",
    "LOGIN_FORGOT_PASSWORD_HEADER": "Request password reset",
    "LOGIN_FORGOT_PASSWORD_MESSAGE": "Enter your email to request the reset password and receive instructions:",
    "LOGIN_FORGOT_PASSWORD_SUBMIT_BUTTON": "Send me a password-reset mail",
    "LOGIN_BACK_TO_HOME_MESSAGE": "Go back to login page",
    "CHANGE_ACCOUNT_SETTINGS_MAIN_HEADER": "Change account settings",
    "CHANGE_ACCOUNT_SETTINGS_SEC_HEADER": "Fill in the fields you wish to modify",
    "CHANGE_USERNAME_NEW_LABEL": "Your username: ",
    "CHANGE_EMAIL_NEW_LABEL": "Your mail address:",
    "CHANGE_ROLE_NEW_LABEL": "Your business title:",
    "CHANGE_MOBILE_NEW_LABEL": "Your phone / mobile number:",
    "CHANGE_CURRENT_LABEL": "Enter current password:",
    "CHANGE_NEW_LABEL": "Enter a new password (min 6 characters):",
    "CHANGE_REPEAT_NEW_LABEL": "Repeat new password:",
    "CHANGE_ACCOUNT_SETTINGS_SUBMIT": "Confirm",
    "ACCOUNT_SETTINGS_CURRENT_PASSWORD_ALONE": "Please fill in the two fields below",
    "ACCOUNT_SETTINGS_CURRENT_PASSWORD_VOID": "You need to fill in the current password field",
    "CHANGE_PSW_SUBMIT": "Change password",
    "AZIENDA_DETAIL_SEC_HEADER": "Company data",
    "USER_DETAIL_SEC_HEADER": "Account data",
    "AZIENDA_DENOM_STRING": "COMPANY NAME",
    "AZIENDA_P_IVA_STRING": "ITALIAN FISCAL CODE (P. IVA)",
    "AZIENDA_SECTOR_STRING": "INDUSTRIAL SECTOR",
    "AZIENDA_REVENUE_STRING": "REVENUE RANGE",
    "AZIENDA_IS_QUOTED_STRING": "LISTING STATUS",
    "AZIENDA_COMPANY_TYPE_STRING": "COMPANY TYPE",
    "AZIENDA_IS_ASSOCIATED_STRING": "MEMBERSHIP STATUS",
    "AZIENDA_EMPLOYEES_RANGE_STRING": "EMPLOYEE RANGE",
    "AZIENDA_DATA_NOT_PROVIDED_STRING": "NOT INDICATED",
    "USER_ACCOUNT_USERNAME_STRING": "USERNAME",
    "USER_ACCOUNT_EMAIL_STRING": "EMAIL ACCOUNT",
    "ENTER_NEW_EMAIL": "New email address",
    "USER_COMPANY_ROLE_STRING": "USER COMPANY ROLE",
    "USER_ACCOUNT_TELEPHONE_STRING": "USER PHONE CONTACT",
    "CHANGE_AZIENDA_SETTINGS_MAIN_HEADER": "Edit company settings",
    "UPDATE_YEARLY_AZIENDA_SETTINGS_MAIN_HEADER":"Update company data for the selected year",
    "CHANGE_AZIENDA_SETTINGS_SEC_HEADER": "Change the current settings of your company",
    "CHANGE_DENOMINATION_LABEL": "New Company name",
    "CHANGE_DENOMINATION_CURRENT": "Your current Company name is:",
    "CHANGE_SETTORE_LABEL": "New industrial sector:",
    "CHANGE_SETTORE_CURRENT_SECTOR": "Your current industrial sector is",
    "CHANGE_CURRENT_ASSOCIATED_OPTION": "Current membership status",
    "CHANGE_CURRENT_QUOTED_OPTION": "Current listing status",
    "CHANGE_FATTURATO_LABEL": "New revenue range:",
    "CHANGE_EMPLOYEES_RANGE": "New employee range:",
    "CHANGE_FATTURATO_CURRENT_SECTOR": "Your current revenue range is",
    "CHANGE_CURRENT_EMPLOYEES_RANGE": "Your current employee range is",
    "CHANGE_AZIENDA_SETTINGS_SUBMIT": "Confirm",
    "CHANGE_AZIENDA_SETTINGS_ALERT_MESSAGE": "Are you sure you want to modify your company settings? Your statistics might result different.",
    "CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL" : "Cancel",
    "CHANGE_AZIENDA_SETTINGS_ABORTED_MESSAGE": "Operation canceled",
    "CHANGE_ASSOCIATED_STATUS_QUESTION": "New associative status: ",
    "CHANGE_COMPANY_TYPE_QUESTION": "New company type: ",
    "CURRENTLY_ASSOCIATED_STRING": " MEMBER OF VALORE D",
    "CURRENTLY_NOT_ASSOCIATED_STRING": " NOT MEMBER OF VALORE D",
    "CHANGE_ASSOCIATED_OPTION": "MEMBER",
    "CHANGE_NOT_ASSOCIATED_OPTION": "NOT MEMBER",
    "CHANGE_QUOTED_STATUS_QUESTION": "New listing status: ",
    "CURRENTLY_QUOTED_STRING": " THE COMPANY IS LISTED",
    "CURRENTLY_NOT_QUOTED_STRING": " THE COMPANY IS NOT LISTED",
    "CHANGE_QUOTED_OPTION": "LISTED",
    "CHANGE_NOT_QUOTED_OPTION": "NOT LISTED",
    "CHANGE_PMI_OPTION": "SMALL-MEDIUM ITALIAN COMPANY (up to 250 employees, <= 50 milion euros of annual revenue)",
    "CHANGE_SMALL_OPTION": "ITALIAN BRANCH OF A FOREIGN GROUP (up to 250 employees)",
    "CHANGE_LARGE_OPTION": "MULTINATIONAL COMPANY, ITALIAN COMPANY OR BRANCH OF A FOREIGN GROUP  (more than 250 employees)",
    "REVERT_ICON_TEXT": "Reset the previous value",
    "SURVEY_INFO_START_ALERT": "Welcome! <br><br> We know that you will need a lot of data and a lot of time to complete the survey. <br><br> For this reason we would like to thank you for your effort and give you the possibility to save any partial filled page, exit the application and return to your toolbox anytime you desire. <br><br> Remember that the accuracy and cohesion of the data you'll provide is fundamental for the reliability of the results.",
    "SURVEY_OPTIONALITY_MESSAGE": "The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.",
    "SURVEY_MESSAGE_STAFF_LINEA": "The <u>line roles</u> are those placed in the operational bodies of the organization (Business Unit, production...). <br>The <u>staff roles</u> are those placed in the support functions of the organization (HR, Finance, Legal, Communication, IT...)",
    
    "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>2-7 (Employees)</i>.
            </li>
            <li>
                <i>405-1 (Diversity of governance bodies and employees)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                In this page, the sum of the totals of each section must correspond to the total number of employees at point 1.1 (with the only exception of point 1.11).
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI_STAFF_LINEA": `
        <u>Line roles</u> are those placed in the organization's operating bodies (Business Unit, Production, Territory...).<br>
        <u>Staff roles</u> are those placed in the support functions of the organization (Human Resources, Finance, Legal Department, Communication, IT...).
    `,
    
    "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>2-9 (Governance structure and composition)</i>.
            </li>
            <li>
                <i>405-1 (Diversity of governance bodies and employees)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter the data relating to the directors of the Board of Directors, taking into consideration all the roles on the list; to learn more about each role, hover over the mouse cursor onto the "i" for info next to the indicator.
            </li>
            <li>
                If there are no directors who cover the roles, then indicate 0 (zero).
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.
            </li>
        </ul>
    `,

    "SURVEY_4_MESSAGE_COMPOSIZIONE_TOP_MANAGEMENT": `
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter at point 4.1 the data relating to the first line of the organization (all the direct reports of the CEO / GM of the company).
            </li>
            <li>
                Enter at points from 4.2 to 4.26 the data relating to the gender of the Directors in Top Management; at points 4.27 and 4.28 it's possible to enter numeric data for BU directors or other directions which are non present on the list. Enter data <u>only</u> for pertinent directions of the organization.
            </li>
            <li>
                If a director of the organizations covers multiple directions, then enter data only for one of them (the most relevant or significant).
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.
            </li>
        </ul>
    `,

    "SURVEY_5_MESSAGE_SUCCESSION_PLAN": `
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                The data entered in this section must refer to the succession plan relative to the last accounting year.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.
            </li>
        </ul>
    `,

    "SURVEY_6_MESSAGE_COMPOSIZIONE_NUOVI_DIPENDENTI": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-1 (New employee hires and turnover)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                In this page, the sum of the totals of each section must correspond to the total number of employees at point 6.1.
            </li>
            <li>
                The data entered in this section must refer to the hirings relative to the last accounting year.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.
            </li>
        </ul>
    `,
    
    "SURVEY_7_MESSAGE_TURNOVER_DIPENDENTI": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-1 (New employee hires and turnover)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                In this page, the sum of the totals of each section must correspond to the total number of employees at point 7.1.
            </li>
            <li>
                The data entered in this section must refer to the hirings and exits relative to the last accounting year.
            </li>
            <li>
                The calculation of turnover is realized through the sum of entrances (same data as page 6) and exits in the accounting year: for example, if 10 women entered the organization and 5 have left, then the data to enter is 15.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory.
            </li>
        </ul>
    `,

    "SURVEY_8_MESSAGE_COMPOSIZIONE_DIPENDENTI": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>404-1 (Average hours of training per year per employee)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                The data entered in this section must refer to the average hours of training in the last accounting year.
            </li>
            <li>
                The data entered in this section must refer to non-mandatory training (exclude security, corruption, antitrust...).
            </li>
            <li>
                The data on the average hours must be calculated keeping into account only the employees who have actually participated in training activities, divided for each category level.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    
    "SURVEY_9_MESSAGE_CONGEDO_PARENTALE": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-3 (Parental leave)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                With the expression "parental leave" it is intended the permission granted to the employee for the absence from work in its optional form, supplementary to that provided by the law.
            </li>
            <li>
                The parental leave is provided for all employees who have at least one child aged 12 or younger.
            </li>
            <li>
                The data entered in this section must refer to the parental leave permits granted in the last accounting year.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    "SURVEY_9_MESSAGE_CONGEDO_PARENTALE_PER_GENERE": `
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                for maximum number is intended the highest number of days granted to one employee during the last accounting year.
            </li>
            <li>
                for average number is intended the average of days granted to all employees divided by gender.
            </li>
            <li>
                for minimum number is intended the lowest number of days granted to one employee during the last accounting year.
            </li>
        </ul>
        Examples:<br>
        Employee #1 = 3 days granted, Employee #2 = 50 days granted, Employee #3 = 60 days granted.<br>
        Maximum number = 60 days (those of Employee #3).<br>
        Average number = average between 60/50/3 = 37.6 days.<br>
        Minimum number = 3 days (those of Employee #1).
    `,
    
    "SURVEY_10_MESSAGE_AVANZAMENTO_CARRIERA": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>404-3 (Percentage of employees receiving regular performance and career development reviews)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                The data entered in this section must refer to the promotions in the Iast accounting year.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    
    "SURVEY_11_MESSAGE_DIFFERENZE_RETRIBUTIVE": `
        The information in this section refer <b>partially</b> to <i>GRI:</i><br>
        <ul>
            <li>
                <i>405-2 (Ratio of basic salary and remuneration of women to men)</i>.
            </li>
        </ul>
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                The Gross Annual Salary (RAL) is composed of a contractual minimum, seniority increase, contingency, super-minimum and supplementary allowance.
            </li>
            <li>
                The Total Annual Salary (RTA) is composed of RAL, variable retribution (bonuses and incentives) and company benefits.
            </li>
            <li>
                The data entered in this section must refer to the salaries in the Iast accounting year.
            </li>
            <li>
                All the values in this section are in Euro (€).
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    
    "SURVEY_12_MESSAGE_FORME_DI_LAVORO_FLESSIBILE": `
        <b><u>INFORMATION FOR COMPILATION</u></b><br>
        <ul>
            <li>
                Enter only the data relating to employees who have a direct contract with the organization (no staff leasing, no contingent workers).
            </li>
            <li>
                Enter the percentages relating to the use of flexible forms of work divided by gender, calculated on the total of the individual genders.
            </li>
            <li>
                The data entered in this section must refer to the practices in the Iast accounting year.
            </li>
            <li>
                The indicators marked with an asterisk (<span class='required'></span> ) are mandatory. If there is no data on the facultative indicators for your organization, then leave the fields in blank.
            </li>
        </ul>
    `,
    
    "SURVEY_PLUS_1_MESSAGE_INTRODUCTION":"<u>INTRODUCTION</u><br>\r\n <ul><li>Respond to the questionnaire in reference to the policies and practices of a specific fiscal year, which you can select at the top of your screen</li><li>Only include in your company’s answers the actual policies and initiatives in place during the fiscal year you are describing, not those planned for the future</li><li>Include the information regarding the Italian perimeter</li><li>You might need to engage other centers of expertise within your organization in order to obtain the correct answers to the questionnaire </li><li>The questions generally require single answers, unless it's clearly specified you can indicate more responses</li></ul>",
    "SURVEY_MEN_STRING":"MEN",
    "SURVEY_WOMEN_STRING": "WOMEN",
    "SURVEY_YES_STRING": "Yes",
    "SURVEY_NO_STRING": "No",
    "SURVEY_SAVE_AND_CONTINUE_BUTTON": "Save and continue",
    "SURVEY_WOMAN_STRING": "Woman",
    "SURVEY_MAN_STRING": "Man",
    "SURVEY_INTEGER_FIELD_PLACEHOLDER": "Insert an integer value",
    "SURVEY_PERCENTAGE_FIELD_PLACEHOLDER": "Insert a percentage value",
    "SURVEY_TEXTUAL_FIELD_PLACEHOLDER": "Insert text",
    "SURVEY_TOTAL_STRING": "TOTAL",
    "SURVEY_CONSTRAINING_ERROR": "The inserted value is greater than the one of the field ",
    "SURVEY_GENDER_FIELD_INCOHERENCY": "The total field is not equal to the sum of women and men partials. Please fix the error\n",
    "SURVEY_LAST_MODIFIED_BY": "Value inserted by user",
    "SURVEY_RESET_VALUE": "Reset",
    "SURVEY_TIME_LAST_MODIFICATION": "at time",
    "SURVEY_FULLY_OPTIONAL_SET": "Is totally optional but STRONGLY SUGGESTED to fill in these questions",
    "SURVEY_FORMATION_HOURS_CONSTRAINING_ERROR": "You MUST fill the field with a PER PERSON value, not the total value of all the employees",
    "SURVEY_FOREIGN_CDA_CONFIRMATION MESSAGE": "Are you sure that your CDA is not based in Italy? By proceeding like this a lot of important question will be skipped and your report will be less accurate",
    "SURVEY_PARTIALLY_UNFILLED_INCOHERENCY": "This field must be filled with a valid value if you already provided another value of the same group",
    "SURVEY_BREADCRUMB_TEXT": "Go back to homepage",
    "SURVEY_GO_BEHIND_BUTTON": "Previous set",
    "SURVEY_GO_AHEAD_BUTTON": "Next Set",
    "SURVEY_LAST_SET_SUBMIT_BUTTON": "Save and finish survey",
    "SURVEY_SUMMARY_GENDER_STRING": "GENDER",
    "SURVEY_SUMMARY_ANSWER_STRING": "ANSWER",
    "SURVEY_SUMMARY_TITLE": "Data summary - Fiscal year ",
    "SURVEY_VOID_STRUCTURE_SUMMARY_TITLE": "Survey structure",
    "SURVEY_STRUCTURE_SUMMARY_GENDER_ALTERNATIVES": "(WOMAN/MAN)",
    "SURVEY_STRUCTURE_SUMMARY_YES_NO_ALTERNATIVES": "(YES/NO)",
    "SURVEY_SUMMARY_PRINT_BUTTON": "Print or save data",
    "SURVEY_INFO_READONLY_SET_ALERT": "This is a read-only question set. Before being allowed to filling it you must complete all the previous ones",
    "DASHBOARD_TEST_HEADER": "COMPILE <br> SURVEY",
    "DASHBOARD_FIRST_SURVEY_MESSAGE": "Start or continue your survey!",
    "DASHBOARD_MODIFY_SURVEY_MESSAGE": "Modify your survey's data",
    "DASHBOARD_SURVEY_BUTTON": "Go to the survey",
    "DASHBOARD_REPORT_HEADER": " VIEW YOUR SCORE",
    "DASHBOARD_REPORT_MESSAGE": "View the detailed score report",
    "DASHBOARD_REPORT_BUTTON": "View your score report",
    "DASHBOARD_III_HEADER": "Your current overall score is:",
    "DASHBOARD_REPORT_TIMESTAMP_MESSAGE": "Registered on: ",
    "DASHBOARD_YEARLY_PERIOD_MESSAGE": "Fiscal Year",
    "DASHBOARD_SELECT_YEAR_INCOMPLETE": " (INCOMPLETE DATA)",
    "DASHBOARD_YEARLY_PARAMETERS_UPDATE_POPUP_TITLE": "UPDATE COMPANY SETTINGS FOR FISCAL YEAR ",
    "DASHBOARD_NEW_YEAR_AVAILABLE_NOTIFICATION": "Welcome back! <br> We inform you that since the last time a new fiscal year has been made available.<br> You can find it into the fiscal year selection menu.",
    "HOMEPAGE_MESSAGE": "<p>The <b>Inclusion Impact Index</b> is an online diagnostic tool that summarizes in a clear and simple way the situation regarding <b>diversity and inclusion</b> in a company, based on the GRI indicators. In particular, this tool provides an industry benchmark in terms of governance, the ability to attract, develop and retain talents from a D&I perspective. The data included in this Index take into consideration exclusively Italy. The data is destined for the internal use of the signed up companies. <b>Valore D treats the data anonymously and in aggregated form.</b></p>",
    "META_DESCRIPTION": "The Inclusion Impact Index is an online diagnostic tool that summarizes in a clear and simple way the situation regarding diversity and inclusion in a company, based on the GRI indicators.",
    "HEADER_REGISTER_BUTTON": "REGISTRATION",
    "HEADER_MY_ACCOUNT_BUTTON": "MY ACCOUNT",
    "HEADER_EDIT_ACCOUNT_SETTINGS_BUTTON": "Edit account settings",
    "HEADER_VOID_SURVEY_STRUCTURE_GENERATOR": "Print survey structure",
    "USER_DETAIL_MAIN_HEADER": "Account detail",
    "HEADER_EDIT_AZIENDA_SETTINGS_BUTTON": "Edit company settings",
    "HEADER_ACCOUNT_DATA_SUMMARY_BUTTON": "Account data",
    "USER_CHANGE_EMAIL_DIALOGUE_BOX_MESSAGE": "Your email change request has been correctly elaborated<br>To complete the operation please click on the link we have sent to your new address",
    "USER_RESET_PASSWORD_DIALOGUE_BOX_MESSAGE": "Your password reset request has been correctly elaborated<br>To complete the operation please click on the link we have sent to your new address",
    "REPORT_III_RESULT_HEADER": "YOUR OVERALL RESULT IS:",
    "REPORT_III_GENERATION_MIX_HEADER": "YOUR GENERATION MIX:",
    "REPORT_KPI_RESULTS_HEADER": "THE INDICATORS IN DETAIL: ",
    "REPORT_AVERAGE_VALUE_STRING": "AVERAGE VALUE",
    "REPORT_EMPLOYEES_COMPARISON_BUTTON": "COMPARE BY EMPLOYEES RANGE",
    "REPORT_SECTOR_COMPARISON_BUTTON": "COMPARE BY INDUSTRIAL SECTOR",
    "REPORT_FATTURATO_COMPARISON_BUTTON": "COMPARE BY REVENUE RANGE",
    "REPORT_III_UNDER_AVERAGE_FEEDBACK": "YOU COULD DO BETTER! DISCOVER YOUR WEAK POINTS AND WORK TO IMPROVE THEM<br>",
    "REPORT_III_IN_THE_AVERAGE_FEEDBACK": "YOUR SCORE IS COMPARABLE WITH THE GENERAL AVERAGE VALUE<br>",
    "REPORT_III_OVER_AVERAGE_FEEDBACK": "CONGRATULATIONS! YOUR ARE DOING A GREAT WORK. KEEP ON WITH THE GOOD WORK!<br>",
    "REPORT_COMPARISON_NO_ROWS_RETRIEVED": "The database does not contain any company report with the selected characteristics",
    "REPORT_COMPARISION_CHART_TITLE_SETTORE": "Comparison of your results with those of the companies of ",
    "REPORT_COMPARISION_CHART_TITLE_FATTURATO": "Comparison of your results with those of the companies of ",
    "REPORT_COMPARISION_CHART_TITLE_EMPLOYEES": "Comparison of your results with those of the companies with ",
    "REPORT_SETTORE": "industrial sector",
    "REPORT_FATTURATO": "revenue range",
    "REPORT_EMPLOYEES": "employee range",
    "REPORT_SELECT_EMPLOYEES_LABEL": "Compare with employee range: ",
    "REPORT_SELECT_SETTORE_LABEL": "Compare with industrial sector: ",
    "REPORT_SELECT_FATTURATO_LABEL": "Compare with revenue range: ",
    "REPORT_INDEX_CHART_AVG_TOOLTIP_LABEL": "Average index score",
    "REPORT_INDEX_CHART_YOUR_SCORE_TOOLTIP_LABEL": "Your company score",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_SECTOR": "Sector average",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_REVENUE": "Revenue range average",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_EMPLOYEES": "Employee range average",
    "REPORT_BONUS_PAYGAP_MESSAGE_FIRST_PART": "YOU HAVE GAINED ",
    "REPORT_BONUS_PAYGAP_MESSAGE_LAST_PART": " EXTRA POINTS FOR YOUR TRANSPARENCY ON GENDER PAY GAP",
    "REPORT_COMPARISON_ALL_EMPLOYEES_RANGES": "ALL THE EMPLOYEE RANGES",
    "REPORT_COMPARISON_ALL_SECTORS": "ALL THE SECTORS",
    "REPORT_COMPARISON_ALL_REVENUES": "ALL REVENUE RANGES",
    "REPORT_SELECT_QUOTED_LABEL": "Compare only with listed companies:",
    "REPORT_SELECT_ASSOCIATED_LABEL": "Compare only with member companies:",
    "REPORT_SELECT_COMPANY_TYPE":"Compare with enterprise type:",
    "REPORT_COMPARISON_INDIFFERENT": "INDIFFERENT",
    "REPORT_COMPARISON_QUOTED_MESSAGE": "ONLY LISTED COMPANIES",
    "REPORT_COMPARISON_NOT_QUOTED_MESSAGE": "ONLY NON-LISTED COMPANIES",
    "REPORT_COMPARISON_ASSOCIATED_MESSAGE": "ONLY MEMBER COMPANIES",
    "REPORT_COMPARISON_NOT_ASSOCIATED_MESSAGE": "ONLY NON-MEMBER COMPANIES",
    "REPORT_COMPARISON_SECTION_HEADER": "Compare your results: ",
    "REPORT_CURRENT_DETAILS_PAGE_AS_PDF": "Print or save this report",
    "REPORT_COMPARISON_CHART_TITLE_WITH_COMPANY_#": "Number of companies included in the comparison : ",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP": "Average value selection",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_HISTORICAL": "Average value selection for related fiscal year",
    "REPORT_OPEN_PROVIDED_DATA_SUMMARY_BUTTON": "Data summary",
    "REPORT_INDEX_RESULTS_FIXED_GOVERNANCE_DESCRIPTION": "This area reflects the company's ability to empower women internally, up to the top positions.",
    "REPORT_INDEX_RESULTS_FIXED_ATTRACTIVENESS_DESCRIPTION": "This area reflects the company's ability to attract young female talents and to then transmit them its own values \u200b\u200babout the issues of D&I.",
    "REPORT_INDEX_RESULTS_FIXED_DEVELOPMENT_DESCRIPTION": "This area reflects the company's ability to value the talent of women already present within the organization, in order to define personalised growth paths to support merit and talent.",
    "REPORT_INDEX_RESULTS_FIXED_RETAINMENT_DESCRIPTION": "This area reflects the company's ability to value female talents in an integrated and continuous way throughout the woman's professional life.",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_GOVERNANCE": "Congratulations! Your company is doing a great job! In your organization you know how to enhance female talent up to the top.",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_GOVERNANCE": "Your company can improve the value of internal female talents. <br>\r\n <b>Why?</b> Because the companies with a highly diversified senior management team achieve better financial results: the EBITDA margin increases by 2% and CFROI by 2.04% in those companies where women cover at least 20% of senior management roles compared to those companies with less than 15% of women in senior management roles*<br>\r\n <b>How?</b> With programs dedicated to top managers, such as the C-Level path of Valore D and Mentorship programs. <br><br>\r\n <i>* Source: Credit Suisse Gender 3000, 2019.</i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_ATTRACTIVENESS": "Congratulations! your company is doing a great job! Young women look to you as a company attentive to these issues and you know how to value them right from the entrance to work",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_ATTRACTIVENESS": "Your company can improve the ability to attract female talent.<br>\r\n <b>Why doing it?</b> Because a strong company reputation increases the will to work for a brand, in fact 70% of consumers are more willing to work in a company with an excellent reputation, compared to 9% of companies with a bad reputation*.<br>\r\n <b>How to do so?</b> With ad hoc projects to raise awareness in schools and plans of growth dedicated to the new generations. <br> Value D can support you with InspirinGirls projects and the specific training path.<br><br>\r\n <i>* Source: Reputation Institute, RepTrack Report 2020.</i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_DEVELOPMENT": "Congratulations! Your company is doing a great job. <br> You are encouraging young women to express their full potential at work!",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_DEVELOPMENT": "Your company can improve the ability to enhance female merit and talent already present within it. <br>\r\n <b> Why? </b> Because the career path of women is like a leaking pipe: in the private sector, women represent on average 44.1% of professional workers, 32.8% of middle managers and only 18.6% of senior/executive managers*. <br>\r\n <b> How to act? </b> Strengthening hard and soft skills. <br> Valore D can support you with the specific training path and Mentorship programs. <br> <br>\r\n <i> * Valore D's Inclusion Impact Index\u00ae, aggregated data 2020. </i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_RETAINMENT": "Congratulations! Your company is doing a great job. <br> Women within the organization continue to be involved and motivated.",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_RETAINMENT": "Your company can improve the ability to value women's merit and talent over time. <br>\r\n <b> Why is it necessary? </b> Because with the continuos raising of the retirement age, women will remain more at work, and it is essential that they continue to be and motivated, avoiding to bring outside the knowledge of the company. <br>\r\n <b> How? </b> Valore D can support you with a specific training path, but also with best practice sharing on topics like shared parenting, pay gap reduction and smart/agile work practices.",
    "REPORT_GOVERNANCE_SKIPPED_ALERT_MESSAGE": "Since you have declared that your company hasn't got the board of directors in Italy, the system hasn't got enough data to compute the Governance indicator.<br>Inside the diagrams the indicator's value will appear as 0.0 <br> You can always modify your choice by declaring an Italian BoD inside the survey filling functionality",
    "REPORT_INDEX_SKIPPED_ALERT_MESSAGE": "One or more D&I indicators have been skipped since the data provided isn't sufficient to guarantee an acceptable reliability of the results.<br>Inside the diagrams the indicator's value will appear as 0.0",
    "REPORT_ALERT_MESSAGE": "WARNING",
    "REPORT_HISTORIC_SELECT_LABEL": "Compare with historical results:",
    "REPORT_COMPARISON_HISTORICAL_MODE_ACTIVE_MESSAGE": "ALL FISCAL YEARS",
    "REPORT_COMPARISON_HISTORICAL_MODE_INACTIVE_MESSAGE": "CURRENT FISCAL YEAR ONLY",
    "SAVE_CONTINUE": "Save and Continue",
    "EMAIL_CONFIRMATION": "Email Verification",
    "EMAIL_VERIFICAION_DESCRIPTION": "Simply click the button below to verify your email address.",
    "VERIFY_EMAIL_ADDRESS": "Verify email address",
    "RESEND_EMAIL_VERIFICATION" : "Resend email verification",
    "BUSINEESS_NAME" : "BUSINESS NAME",
    "LAST_SECTOR" : "LAST SECTOR",
    "VAT_NUMBER" : "VAT NUMBER",
    "CREATION_DATE" : "CREATION DATE",
    "USER_NAME" : "USER NAME",
    "EMAIL" : "EMAIL",
    "ACTIVE" : "ACTIVE",
    "Actions" : "Actions",
    "SEARCH_USERS" : "Search Users",
    "VAT_NUMBER_REQUIRED" : "Vat number is a required field.",
    "LEGAL_ENTITY_REQUIRED" : "Legal entity is a required field.",
    "INDUSTRIAL_SECTOR_REQUIRED" : "Industrial sector is a required field.",
    "REVENUE_RANGE_REQUIRED" : "Revenue range is a required field.",
    "USERNAME_REQUIRED" : "Username is a required field.",
    "EMAIL_REQUIRED" : "Email is a required field.",
    "EMAIL_OR_USERNAME_REQUIRED" : "Email or username is a required field.",
    "EMAIL_CONFIRMATION_REQUIRED" : "Email confirmation is a required field.",
    "PASSWORD_REQUIRED" : "Password is a required field.",
    "PASSWORD_CONFIRMATION_REQUIRED" : "Password confirmation is a required field.",
    "MEMBERSHIP_REQUIRED" : "Membership status is a required field.",
    "LISTING_REQUIRED": "Listing status is a  required field.",
    "COMPANY_TYPE_REQUIRED" : "Company type is a  required field.",
    "VALID_EMAIL" : "Email should be valid email",
    "PASSWORD_LENGTH_VALIDATION" : "Password should be more than 6 characters.",
    "PASSWORD_MUST_MATCH" : "Passwords must match",
    "EMAIL_MUST_MATCH" : "Email must match",
    "BUTTON_DISCARD_CHANGES" : "Discard",
    "BUTTON_SAVE_CHANGES" : "Save Changes",
    "HOMEPAGE_PAGE_TITLE": 'Home - Inclusion Impact Index Plus',
    "AUTH_PAGE_TITLE": 'Login - Inclusion Impact IndexPlus',
    "RESET_PASSWORD_PAGE_TITLE": "Reset Password - Inclusion Impact Index Plus",
    "LABEL_USER_PROFILE" : "User Profile",
    "BUTTON_CHANGE_EMAIL" : "Change Email",
    "BUTTON_CHANGE_PASSWORD" : "Reset Password",
    "FEEDBACK_PASSWORD_RESET_FAIL": "Impossible to reset password.",
    "BUTTON_SUBMIT" : "Submit",
    "BUTTON_WAIT" : "Please wait...",
    "COMPANY_NAME_REQUIRED" : "Company name is a required field.",
    "EMPLOYEE_RANGE_REQUIRED" : "Employee range is a required field.",
    "USERNAME_CHARACTER_VALIDATION" : "Username should be the combination of letters and numbers, 2-64 characters.",
    "SIGNUP_PAGE_TITLE": "Sign Up - Inclusion Impact Index Plus",
    "SIGNIN_PAGE_TITLE": "Sign In - Inclusion Impact Index Plus",
    "SIGN_OUT": "Sign out",
    "UPDATE_LOGIN_CREDENTIAL":"Update login credentials",
    "ACTIVE_LABEL": "Active",
    "INACTIVE_LABEL": "Inactive",
    "CONTACT_US_LABEL": "Contact us",
    "CONTINUE": "Continue",
    "BACK": "Back",
    "BUTTON_RESULT": "See the results",
    "BUTTON_MODIFICATION": "Update",
    "TITLE_CURRENT_RESULT": "Your current result",
    "TITLE_SURVEY_PLUS_OVERALL_RESULT": "Your result Inclusion Plus",
    "TITLE_SURVEY_PLUS_PIE_RESULT": "Your result in detail",
    "SURVEY_PLUS_LABEL_NUMBER_OF_COMPARISON": "Number of companies included in the benchmark",
    "SURVEY_PLUS_LABEL_DIVERSITY": "Policy scope" ,
    "SURVEY_PLUS_LABEL_POLICY": "Diversity spread",
    "SURVEY_PLUS_LABEL_DIVERSITY_DETAIL": "Diversity spread",
    "SURVEY_PLUS_LABEL_POLICY_DETAIL": "Policy scope" ,
    "SURVEY_PLUS_LABEL_YOUR_COMPANY":"Your company",
    "SURVEY_PLUS_LABEL_AVERAGE_COMPANIES":"Average companies",
    "SURVEY_PLUS_HOW_TO_READ": "How to read this data",
    "SURVEY_PLUS_HOW_TO_READ_DETAIL": "<strong>POLICY SCOPE</strong><br>It indicates the company’s ability to work on DEI and its principles in all processes in the organization.<br><br><strong>DIVERSITY SPREAD</strong><br>It indicates the company’s ability to produce actions on various aspects of diversity.<br><br><strong>DIVERSITY FREQUENCY</strong><br>It indicates the frequency through which the company includes each aspect of diversity in the organizational processes.<br><br>All the questions contribute to the measurement of the Policy Scope, while only some of the questions provide an insight into the six types of diversity.<br><br>Therefore the weight of each section has a different impact on the overall result of the Inclusion Plus.",
    "LABEL_COMPARE_SURVEY_PLUS_CHART": "Compare your results: ",
    "LABEL_COMPARE_YEAR_SURVEY_PLUS_CHART": "Compare with the historical year",
    "TITLE_SURVEY_PLUS_RADAR_RESULT": "Frequency of the six diversities",
    "SURVEY_PLUS_LABEL_GENRE":"Gender",
    "SURVEY_PLUS_LABEL_AGE":"Age / generation",
    "SURVEY_PLUS_LABEL_DISABILIY":"Disability / ability",
    "SURVEY_PLUS_LABEL_CULTURE":"Culture",
    "SURVEY_PLUS_LABEL_BACKGROUND":"Background",
    "SURVEY_PLUS_LABEL_SEX":"Sexual orientation",
    "THEADER_COMPANY_SURVEY_PLUS_RADAR_DETAIL": "Your company",
    "THEADER_RESULT_SURVEY_PLUS_RADAR_DETAIL": "Results",
    "TITLE_SURVEY_PLUS_RADAR_DETAILS": "Details data",
    "SMALL_TITLE_SURVEY_PLUS_RADAR_DETAILS": "Compare",
    "TEXT_NO_DESCRIPTION": "There's no description",
    "TITLE_YEAR": "Fiscal year",
    "BUTTON_CLOSE": "Close",
    "SAVE_FINSH": "Save and Finsh",
    "FIELD_REQUIRED" : "This field is required!",
    "FIELD_SHOULD_BE_NUMBER" : "This field should be number!",
    "FIELD_SHOULD_BE_INTEGER" : "This field should be an integer!",
    "REGISTRATION_PROCESS":"Do you want to proceed with registration request?",
    "SURVEY_ERROR_MESSAGE" : "We found an error during form editing. Please verify the values, then proceed to save the page.",
    "SELECT_ONE_OPTION": "Select at least one option!",
    "SELECT_AN_OPTION": "Select an option!",
    "REPORT_III_GENERATION_MIX_LABEL": "Distribution of employees by gender and age range",
    "INCONSISTENT_DATA_TOOLTIP_LABEL": "Inconsistent data on the questionnaire",
    "WOMEN_BY_AGO_RANGE": "Women by age range",
    "MEN_BY_AGO_RANGE": "Men by age range",
    "NEW_EMPLOYEES_LABEL": "NEW EMPLOYEES",
    "EXITED_EMPLOYEES": "EXITED EMPLOYEES",
    "REPORT_INCOMPLETED_YEAR_REDIRECT_MESSAGE": "In order to see this page you should complete the survey of the year ",
    "NOT_CHANGE_YEAR_MESSAGE": "This year cannot be selected ",
    "AGREE_REQUIRED" : "You should accept the  personal data agreement",
    "III_PLUS_LOGO":"Inclusion Impact Index Plus Logo",
    "III_PLUS_COMPACTED_LOGO":"Compacted Inclusion Impact Index Plus Logo",
    "VALORED_PROJECT_LOGO":"A project by Valore D",
    "LANDING_HOW_MUCH_INCLUSIVE" :"<p style='line-height: normal;'>How<br>inclusive is<br>your company?</p>",
    "LANDING_DISCOVER_III":"Find out by using the <nobr>Inclusion Impact Index Plus</nobr>, the tool we designed for all companies that want to monitor and evaluate their <span class=\"text-yellow\"> Diversity</span>, <span class=\"text-light-pink\"> Equity</span> and <span class=\"text-custom-cyan\">Inclusion</span> policies.",
    "LANDING_MORE_OPPORTUNITIES":"More opportunities for you.",
    "LANDING_EASY_AND_SIMPLE_TO_USE":"Easy and simple to use",
    "LANDING_EASY_AND_SIMPLE_TO_USE_DETAIL":"Fill in the questionnaire, save your progress and complete it whenever you want.",
    "LANDING_COMPARE_WITH_OTHER_COMPANY":"Compare with other companies",
    "LANDING_COMPARE_WITH_OTHER_COMPANY_DETAIL":"Find out how your company is ranked in relation to other organizations.",
    "LANDING_COMPLETE_OVERVIEW":"Complete overview ",
    "LANDING_COMPLETE_OVERVIEW_DETAIL":"With personalised reports you can identify areas of strength and areas where your company could improve.",
    "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION":"Preparation for certification",
    "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION_DETAIL":"Compare the result with the requirements of the UNI 125:2022 reference practice.",
    "LANDING_HOW_IT_WORKS":"How does it work?",
    "LANDING_HOW_IT_WORKS_REGISTER":"Register on the platform to gain access to the designated section.",
    "LANDING_HOW_IT_WORKS_FILL_IN_INFO":"Fill in the questionnaires with the flexibility to save your progress and resume whenever you want.",
    "LANDING_HOW_IT_WORKS_COMPARE":"View the result and compare it with the benchmark.",
    "LANDING_EVEN_MORE_POWERFUL":"Even more empowering, even more inclusive.",
    "LANDING_EVEN_MORE_POWERFUL_DETAIL":"By filling out the two questionnaires, you will receive two customised reports that truly embrace all diversity.",
    "LANDING_EVEN_MORE_POWERFUL_QUANTITATIVE_REPORT":"Quantitative Report",
    "LANDING_EVEN_MORE_POWERFUL_QUANTITATIVE_REPORT_DETAIL":"It collects and analyses all your organization's numbers on gender and generational balance on the basis of the indicators that meet the non-financial reporting obligation (Legislative Decree No 254 of 30th December 2016).",
    "LANDING_EVEN_MORE_POWERFUL_QUALITATIVE_REPORT":"Qualitative Report",
    "LANDING_EVEN_MORE_POWERFUL_QUALITATIVE_REPORT_DETAIL":"It collects all the initiatives and policies related to the six types of diversity in the company and provides your organisation's ranking on the subject.",
    "LANDING_III_PLUS":"Inclusion Impact Index Plus",
    "LANDING_III_PLUS_DETAIL":"Multiply opportunities to create a more inclusive world of work.",
    "LANDING_VIDEO": "Video explanation of the Inclusion Impact Index Plus tool",
    "DASHBOARD_III_DESCRIPTION":"The quantitative section <label class=\"text-red\">Inclusion Impact Index</label> collects and analyses all the organisation's numbers on gender and generational balance on the basis of the indicators that meet the non-financial disclosure obligation (Legislative Decree No. 254 of 30 December 2016). ",
    "DASHBOARD_PLUS_DESCRIPTION":"The qualitative section <label class=\"text-custom-cyan\">Inclusion Plus</label> brings together all the initiatives and policies related to the six types of diversity present in the company and returns the organisation's positioning on the issue. ",
    "DASHBOARD_BUTTON_START_THE_SURVEY": "Start the survey",
    "DASHBOARD_BUTTON_CONTINUE_THE_SURVEY": "Continue the survey",
    "LABEL_NUMBER_OF_COMPANY" : "Number of companies included in the comparison",
    "SURVEY_BUTTON_DOWNLOAD_EMPTY_SURVEY":"Download blank questionnaire",
    "SURVEY_BUTTON_DOWNLOAD_SURVEY_WITH_DATA":"Download completed questionnaire",
    "OPTIONAL":"Optional",
    "REGISTER_NOW":"Yes, Register now!",
    "REGISTER_CANCEL_BUTTON":"Cancel",
    "REFUSE_LABEL": "Refuse",
    "PASSWORD_SUCCESS_UPDATED_MESSAGE": "The password has been successfully updated!",
    "EMAIL_SUCCESS_UPDATED_MESSAGE": "Your email has been successfully updated! Check your inbox to verify your address",
    "PREFERENCE_COOKIE_LABEL": "Cookie Preferences",
    "COOKIE_POLICY_LABEL": "Cookie Policy",
    "PRIVACY_POLICY_LABEL": "Privacy Policy",
    "CONFIRM_PASSWORD": "Confirm password",
    "CONFIRM_PASSWORD_REQUIRED": "Confirm password is a required field.",
    "NEW_PASSWORD":"New password",
    "CONFIRM_NEW_PASSWORD":"Confirm new password",

    "DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_CONTENT": "This is a read-only set of questions. You must complete all previous sets before you can complete it.",
    "DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_BACK"   : "Back",
    "DIALOG_WARNING_UNFINISHED_SURVEY_CONTENT"         : "Continuing will lose the changes entered. Press Back to save them, Ok to continue.",
    "DIALOG_WARNING_UNFINISHED_SURVEY_BACK"            : "Back",

    "REPORT_INCOMPLETED_YEAR_MESSAGE": "The survey was not completed for the year ",
    "SURVEY_PLUS_OLDER_THAN_2021": "It is not possible to complete the Plus survey for years prior to 2021.",
    
    "MISSING_YEARLY_PARAMETERS": "Insert the company's information for the year ",

    "SURVEY_TEXTAREA_LABEL": "(DESCRIPTION)",

    "HEADER_USER_GUIDE": "User guide",

    "CONFIRM_MODAL_BUTTON_MESSAGE_CLOSE": "Back to Modify",
    "CONFIRM_MODAL_BUTTON_MESSAGE_CONTINUE": "Save and Continue",

    "CHANGE_COMPANY_TYPE_ALERT_MESSAGE": "Are you sure you want to modify your company type? The modification implies the cancellation of Inclusion Plus questionnaire for the selected year. You will need to enter it again.",

    "SMALL_SCREEN_WARNING_MESSAGE": "For an optimal use of the tool, it is recommended to use a larger display device such as a tablet or a desktop computer.",

    "ADMIN_TABLE_UPDATE_USER_SUCCESS_MESSAGE": "User successfully updated!",
    "ADMIN_TABLE_UPDATE_COMPANY_SUCCESS_MESSAGE": "Company successfully updated!",

    "EDIT_ACCOUNT_SETTINGS_MODAL_TITLE": "Edit Account Settings",
    "EDIT_COMPANY_SETTINGS_MODAL_TITLE": "Edit Company Settings",

    "EDIT_PASSWORD_MODAL_NEW_PASSWORD_LABEL": "New Password",
    "EDIT_PASSWORD_MODAL_NEW_PASSWORD_CONFIRMATION_LABEL": "Confirm New Password",

    "VIEW_LABEL_ADMIN_PANEL": "View",

    "ADMIN_PANEL_TO_APPROVE_USERS": "ACCOUNTS TO BE APPROVED",
    "ADMIN_PANEL_APPROVED_USERS": "ACCOUNTS APPROVED",

    "EXPORT_RESPONSE_SUMMARY_BUTTON_LABEL": "Export response summary",
    "EXPORT_ACCOUNTS_BUTTON_LABEL": "Export accounts",

    "ADMIN_PANEL_ACTIONS_DROPDOWN_LABEL": "Actions",

    "LANGUAGE_SELECTED": "English Language Selected",
    "ADMIN_PANEL_LABEL": "Admin panel",
    "FACE_OF_A_PERSON": "Face of a person",
    "TEST_ICON_LABEL": "Test",
    "SCORE_ICON_LABEL": "Score",
    "INFO_REPORT_ICON_LABEL": "Report Information",

    "DASHBOARD_WIDGET_TITLE_1": "Is your company not yet part of Valore D?",
    "DASHBOARD_WIDGET_TITLE_2": "It's the perfect moment to find out more about our network!",
    "DASHBOARD_WIDGET_SUBTITLE": "To learn more about how to join and all the activities in place for our member companies.",
    "DASHBOARD_WIDGET_BUTTON_CONTACT_US": "Contact us",
};

export default en_messages;
