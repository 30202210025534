const it_messages = {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pagine",
    profile: "Profilo",
    profileOverview: "Overview",
    projects: "Progetti",
    campaigns: "Campagne",
    documents: "Documenti",
    connections: "Connessioni",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Profilo",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Chat privata",
    groupChat: "Chat di gruppo",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    languages:"Lingue",
    agentList:"Lista agenti",
    addAgent : "Aggiungi agente",
    agentsMenu:"Agenti",
    quotationMenu : "Preventivo",
    projectsMenu:"Progetti",
    Projects:"Progetti",
    calendarApp : "Calendario",
    menuTreeListing: "Lista ad albero",
    treeListing : "Alberatura",
    //Login index view texts
    "FEEDBACK_UNKNOWN_ERROR":"Errore sconosciuto durante l'operazione!",
    "FEEDBACK_DELETED":"Il tuo account non \u00e8 pi\u00f9 attivo. Contatta l'amministratore per maggiori informazioni",
    "FEEDBACK_ACCOUNT_SUSPENDED":"Account sospeso per: ",
    "FEEDBACK_ACCOUNT_SUSPENSION_DELETION_STATUS":"Le impostazioni di sospensione/cancellazione sono state modificate correttamente per questo utente",
    "FEEDBACK_ACCOUNT_CANT_DELETE_SUSPEND_OWN":"Non puoi sospendere o cancellare il tuo stesso account",
    "FEEDBACK_ACCOUNT_USER_SUCCESSFULLY_KICKED":"L'utente selezionato \u00e8 stato correttamente sloggato dal sistema (sessione cancellata)",
    "FEEDBACK_PASSWORD_WRONG_3_TIMES":"Hai tentato di inserire la password in maniera errata per 3 o pi\u00f9 volte. Attendi 30 secondi prima di ritentare",
    "FEEDBACK_ACCOUNT_NOT_ACTIVATED_YET":"Il tuo account non \u00e8 ancora stato attivato. Per favore conferma la tua mail attraverso il link che ti abbiamo inviato",
    "FEEDBACK_USERNAME_OR_PASSWORD_WRONG":"La combinazione username/password \u00e8 errata. Per favore tenta un'altra volta",
    "FEEDBACK_USER_DOES_NOT_EXIST":"Questo utente non esiste",
    "FEEDBACK_AZIENDA_DOES_NOT_EXIST":"Questa azienda non esiste",
    "FEEDBACK_LOGIN_FAILED":"Login fallito",
    "FEEDBACK_LOGIN_FAILED_3_TIMES":"Login fallito per 3 o pi\u00f9 volte. Prego aspettare 30 secondi prima di ritentare",
    "FEEDBACK_USERNAME_FIELD_EMPTY":"Il campo username \u00e8 vuoto",
    "FEEDBACK_PASSWORD_FIELD_EMPTY":"Il campo password \u00e8 vuoto",
    "FEEDBACK_USERNAME_OR_PASSWORD_FIELD_EMPTY":"Il campo username o il campo password sono vuoti",
    "FEEDBACK_USERNAME_EMAIL_FIELD_EMPTY":"Campi Username/password non compilati",
    "FEEDBACK_EMAIL_FIELD_EMPTY":"Il campo email non \u00e8 stato compilato",
    "FEEDBACK_EMAIL_REPEAT_WRONG":"I campi email e ripeti email non coincidono",
    "FEEDBACK_EMAIL_AND_PASSWORD_FIELDS_EMPTY":"I campi email e password non sono stati compilati",
    "FEEDBACK_USERNAME_SAME_AS_OLD_ONE":"L'username inserito \u00e8 gi\u00e0 stato scelto da un altro utente. Si prega di immetterne uno differente",
    "FEEDBACK_USERNAME_ALREADY_TAKEN":"L'username inserito \u00e8 gi\u00e0 stato scelto da un altro utente. Si prega di immetterne uno differente",
    "FEEDBACK_USER_EMAIL_ALREADY_TAKEN":"L'email inserita appartiene gi\u00e0 ad un altro utente. Si prega di immetterne una differente",
    "FEEDBACK_USERNAME_CHANGE_SUCCESSFUL":"Il tuo username \u00e8 stato correttamente modificato",
    "FEEDBACK_USERNAME_AND_PASSWORD_FIELD_EMPTY":"I campi username e password non sono stati compilati",
    "FEEDBACK_USERNAME_DOES_NOT_FIT_PATTERN":"L'username inserito non rispetta il formato corretto: sono ammessi solo caratteri a-z e la lunghezza deve essere compresa tra 2 e 64 caratteri",
    "FEEDBACK_EMAIL_DOES_NOT_FIT_PATTERN":"L'email inserita non rispetta il formato corretto",
    "FEEDBACK_EMAIL_SAME_AS_OLD_ONE":"L'email inserita \u00e8 uguale a quella gi\u00e0 esistente. Scegli un'altra email",
    "FEEDBACK_EMAIL_CHANGE_SUCCESSFUL":"Il tuo indirizzo email \u00e8 stato correttamente cambiato",
    "FEEDBACK_CAPTCHA_WRONG":"I caratteri captcha inseriti non sono corretti",
    "FEEDBACK_PASSWORD_REPEAT_WRONG":"I campi password e ripeti password non coincidono",
    "FEEDBACK_PASSWORD_TOO_SHORT":"La password deve avere una lunghezza minima di 6 caratteri",
    "FEEDBACK_USERNAME_TOO_SHORT_OR_TOO_LONG":"La lunghezza dell'username deve essere compresa tra 2 e 64 caratteri",
    "FEEDBACK_ACCOUNT_SUCCESSFULLY_CREATED":"Il tuo account \u00e8 stato creato. Per confermare i dati clicca sul link che ti abbiamo inviato alla mail inserita",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_FAILED":"Al momento \u00e8 impossibile inviarti la mail di verifica. Il tuo account NON \u00e8 stato creato. Per favore contatta il team di supporto",
    "FEEDBACK_ACCOUNT_CREATION_FAILED":"La tua richiesta di registrazione \u00e8 fallita. Per favore torna indietro e compilala di nuovo. Se il problema persiste contatta il team di supporto",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_ERROR":"La mail di verifica non \u00e8 stata inviata a causa di:",
    "FEEDBACK_VERIFICATION_MAIL_SENDING_SUCCESSFUL":"La mail di verifica \u00e8 stata correttamente inviata",
    "FEEDBACK_ACCOUNT_ACTIVATION_SUCCESSFUL":"Il tuo indirizzo email \u00e8 stato verificato.<br> Per poter usufruire dei servizi dovrai attendere fino a quando l'amministratore non approver\u00e0 il tuo account<br>",
    "FEEDBACK_ADMIN_APPROVAL_MAIL_SENDING_SUCCESSFUL":"L'amministratore \u00e8 gi\u00e0 stato avvertito della tua richiesta <br> Riceverai una mail di notifica non appena la pratica sar\u00e0 definitivamente approvata.<br>Questo passaggio richieder\u00e0 al massimo 24h",
    "FEEDBACK_ACCOUNT_ACTIVATION_FAILED":"La combinazione id/codice di verifica non esite! Il sistema anti-spamming del tuo mail provider potrebbe automaticamente scansionare i link presenti nei messaggi, e questo link di verifica potrebbe essere stato raggiunto automaticamente. Prova a loggarti dalla pagina principale",
    "FEEDBACK_AVATAR_UPLOAD_SUCCESSFUL":"Il caricamento dell'immagine di profilo \u00e8 avvenuto correttamente",
    "FEEDBACK_AVATAR_UPLOAD_WRONG_TYPE":"Sono supportati solamente file JPEG o PNG",
    "FEEDBACK_AVATAR_UPLOAD_TOO_SMALL":"L'immagine caricata deve avere una risoluzione minima di 100x100 pixels",
    "FEEDBACK_AVATAR_UPLOAD_TOO_BIG":"Il file caricato \u00e8 troppo grande. Max 5Mb",
    "FEEDBACK_AVATAR_FOLDER_DOES_NOT_EXIST_OR_NOT_WRITABLE":"La cartella Avatar non esiste o non \u00e8 raggiungibile",
    "FEEDBACK_AVATAR_IMAGE_UPLOAD_FAILED":"Qualcosa \u00e8 andato storto durante il caricamento dell'immagine",
    "FEEDBACK_AVATAR_IMAGE_DELETE_SUCCESSFUL":"Immagine correttamente cancellata",
    "FEEDBACK_AVATAR_IMAGE_DELETE_NO_FILE":"Non hai ancora un'immagine di profilo",
    "FEEDBACK_AVATAR_IMAGE_DELETE_FAILED":"Qualcosa \u00e8 andato storto durante la cancellazione della tua immagine di profilo",
    "FEEDBACK_PASSWORD_RESET_TOKEN_FAIL":"Impossibile salvare il token nel database",
    "FEEDBACK_PASSWORD_RESET_TOKEN_MISSING":"Il token di reset password non esiste",
    "FEEDBACK_PASSWORD_RESET_MAIL_SENDING_ERROR":"La mail di reset password non \u00e8 stata inviata a causa: ",
    "FEEDBACK_PASSWORD_RESET_MAIL_SENDING_SUCCESSFUL":"Una mail di reset password \u00e8 stata correttamente inviata",
    "FEEDBACK_PASSWORD_RESET_LINK_EXPIRED":"Il tuo link di reset password \u00e8 scaduto. Devi utilizzarlo entro un'ora",
    "FEEDBACK_PASSWORD_RESET_COMBINATION_DOES_NOT_EXIST":"La combinazione username/codice di verifica non esiste",
    "FEEDBACK_PASSWORD_RESET_LINK_VALID":"Il link di reset password \u00e8 valido. Cambia la password ora",
    "FEEDBACK_EMAIL_CHANGE_FAILED":"La tua richiesta di cambio mail \u00e8 fallita. Se il problema dovesse persistere contatta il team di supporto",
    "FEEDBACK_PASSWORD_CHANGE_SUCCESSFUL":"Password correttamente modificata",
    "FEEDBACK_PASSWORD_CHANGE_FAILED":"La tua richiesta di cambio password \u00e8 fallita. Se il problema dovesse persistere contatta il team di supporto",
    "FEEDBACK_PASSWORD_NEW_SAME_AS_CURRENT":"La nuova password \u00e8 uguale a quella precedente",
    "FEEDBACK_PASSWORD_CURRENT_INCORRECT":"La password inserita non \u00e8 corretta",
    "FEEDBACK_ACCOUNT_TYPE_CHANGE_SUCCESSFUL":"Il cambio di privilegio utente ha avuto successo",
    "FEEDBACK_ACCOUNT_TYPE_CHANGE_FAILED":"Il cambio di privilegio utente \u00e8 fallito",
    "FEEDBACK_NOTE_CREATION_FAILED":"Creazione nota fallita",
    "FEEDBACK_NOTE_EDITING_FAILED":"Modifica nota fallita",
    "FEEDBACK_NOTE_DELETION_FAILED":"Cancellazione nota fallita",
    "FEEDBACK_COOKIE_INVALID":"Il tuo remember-me cookie non \u00e8 valido",
    "FEEDBACK_COOKIE_COMPANY_EDIT_SUCCESSFUL":"Il profilo aziendale \u00e8  stato modificato correttamente",
    "FEEDBACK_COOKIE_LOGIN_SUCCESSFUL":"Eri correttamente loggato attraverso un remeber-me cookie",
    "FEEDBACK_ERROR_WHILE_GETTING_SETTORI_LIST":"Errore durante il caricamento della lista dei settori economici dal database",
    "FEEDBACK_ERROR_WHILE_GETTING_FATTURATI_LIST":"Errore durante il caricamento della lista dei fatturati dal database",
    "FEEDBACK_AZIENDA_CORRECTLY_MODIFIED":"L'operazione sull'azienda \u00e8 stata correttamente effettuata",
    "FEEDBACK_USER_EMAIL_CORRECTLY_SENT":"Una mail di notifica dell'operazione \u00e8 stata correttamente inviata all'utente",
    "FEEDBACK_USER_EMAIL_NOT_SENT":"Errore sconosciuto nel sistema di invio email: l'utente potrebbe non essere stato avvisato dell'operazione",
    "FEEDBACK_DEADLINE_SET_TO_PAST":"Impossibile completare l'operazione: la data scelta non \u00e8 una data futura",
    "FEEDBACK_DEADLINE_SET_AFTER_LIMIT":"La data inserita \u00e8 oltre il limite massimo del sistema (31/12/2030)",
    "FEEDBACK_GENERIC_OPERATION_ERROR":"Impossibile portare a termine l'operazione. Verificare la correttezza dell'input",
    "FEEDBACK_GENERIC_OPERATION_SUCCESS":"La richiesta \u00e8 stata completata con successo",
    "FEEDBACK_GENERIC_DB_ERROR":"Errore di interfacciamento con il database. Si prega di contattare l'amministratore",
    "FEEDBACK_SURVEY_SET_CORRECTLY_SAVED":"I valori inseriti nel set precedenti sono stati correttamente salvati",
    "FEEDBACK_SURVEY_SAVING_ERROR":"Errore durante il salvataggio dei valori inseriti",
    "FEEDBACK_SURVEY_CORRECTLY_ENDED":"Il questionario \u00e8 stato completato correttamente",
    "FEEDBACK_AZIENDA_NOT_YET_APPROVED":"Il tuo account \u00e8 in fase di approvazione. Si prega di attendere la mail di conferma",
    "FEEDBACK_CONCURRENT_SESSION_FORCED_LOGOUT":"Sei gi\u00e0 loggato in questo account da questo o da altri dispositivi. Chiudi le sessioni correnti prima di ritentare",
    "FEEDBACK_SECTOR_MODIFICATION_SUCCESS":"Il settore economico della tua azienda \u00e8 stato modificato correttamente",
    "FEEDBACK_SECTOR_MODIFICATION_ERROR":"Impossibile modificare il settore economico della tua azienda. Tenta ancora",
    "FEEDBACK_FATTURATO_MODIFICATION_SUCCESS":"La fascia di fatturato della tua azienda \u00e8 stato modificata correttamente",
    "FEEDBACK_FATTURATO_MODIFICATION_ERROR":"Impossibile modificare la fascia di fatturato della tua azienda. Tenta ancora",
    "FEEDBACK_EMPLOYEES_RANGE_MODIFICATION_SUCCESS":"Il range dipendenti della tua azienda \u00e8 stato modificata correttamente",
    "FEEDBACK_EMPLOYEES_RANGE_MODIFICATION_ERROR":"Impossibile modificare il range dipendenti della tua azienda. Tenta ancora",
    "FEEDBACK_ASSOCIATED_STATUS_MODIFICATION_SUCCESS":"Lo stato associativo della tua azienda \u00e8 stato modificato correttamente",
    "FEEDBACK_ASSOCIATED_STATUS_MODIFICATION_ERROR":"Impossibile modificare lo stato associativo della tua azienda. Tenta ancora",
    "FEEDBACK_QUOTED_STATUS_MODIFICATION_SUCCESS":"Lo stato di quotazione della tua azienda \u00e8 stato modificato correttamente",
    "FEEDBACK_QUOTED_STATUS_MODIFICATION_ERROR":"Impossibile modificare lo stato di quotazione della tua azienda. Tenta ancora",
    "FEEDBACK_DENOM_MODIFICATION_SUCCESS":"La ragione sociale della tua azienda \u00e8 stata modificata correttamente",
    "FEEDBACK_DENOM_MODIFICATION_ERROR":"Impossibile modificare la ragione sociale della tua azienda. Tenta ancora",
    "REGISTRATION_MAIN_HEADER":"Richiedi un nuovo account per il servizio!",
    "REGISTRATION_P_IVA_PLACEHOLDER":"Partita IVA (11 cifre)",
    "REGISTRATION_DENOMINATION_PLACEHOLDER":"Ragione sociale",
    "REGISTRATION_INDUSTRIAL_SECTOR_MESSAGE":"Seleziona il tuo settore economico",
    "REGISTRATION_INDUSTRIAL_FOOD_AND_BEVERAGE":"Alimentare",
    "REGISTRATION_REVENUE_RANGE_MESSAGE":"Seleziona la tua fascia di fatturato",
    "REGISTRATION_EMPLOYEE_RANGE_MESSAGE": "Seleziona il tuo range di dipendenti",
    "REGISTRATION_USERNAME": "Scegli un nome utente",
    "REGISTRATION_USERNAME_PLACEHOLDER":"Inserisci tra 2 e 64 caratteri tra 0-9 a-z A-Z  _ (spazi non ammessi)",
    "REGISTRATION_EMAIL":"Inserisci il tuo indirizzo email",
    "REGISTRATION_EMAIL_PLACEHOLDER":"Inserisci una email valida",
    "REGISTRATION_EMAIL_REPEAT_PLACEHOLDER":"Ripeti l'indirizzo email",
    "REGISTRATION_PASSWORD_PLACEHOLDER":"Inserisci almeno 6 caratteri",
    "REGISTRATION_PASSWORD_REPEAT_PLACEHOLDER":"Conferma password",
    "REGISTRATION_ROLE_PLACEHOLDER":"Specifica il tuo ruolo aziendale",
    "REGISTRATION_PHONE":"Numero di telefono",
    "REGISTRATION_PHONE_PLACEHOLDER":"Inserisci solo caratteri numerici (spazi non ammessi)",
    "REGISTRATION_CAPTCHA_PLACEHOLDER":"Inserisci i caratteri visualizzati",
    "REGISTRATION_RELOAD_CAPTCHA":"Carica nuovi caratteri",
    "REGISTRATION_SUBMIT_BUTTON":"Richiedi account",
    "TRY_AGAIN_SUBMIT_BUTTON": "Prova ancora",
    "REGISTRATION_OTHER_SECTOR_PLACEHOLDER":"Descrivi il tuo settore (max 128 caratteri)",
    "REGISTRATION_P_IVA_INVALID":"Inserisci esattamente 11 caratteri numerici",
    "REGISTRATION_DENOM_INVALID":"Inserisci max 128 caratteri ( \", &, ', <, > non ammessi) ",
    "REGISTRATION_USERNAME_INVALID":"Max 64 caratteri a scelta tra 0-9 a-z A-Z  _ (spazi non ammessi)",
    "REGISTRATION_EMAIL_INVALID":"Inserire una mail in formato valido",
    "REGISTRATION_PASSWORD_INVALID":"La password deve essere di almeno 6 caratteri",
    "REGISTRATION_EMAIL_REPEAT_NOT_MATCHED":"I campi email non combaciano",
    "REGISTRATION_PASSWORD_REPEAT_NOT_MATCHED":"I campi password non combaciano",
    "REGISTRATION_ROLE_INVALID":"Inserisci max 64 caratteri a scelta tra a-z A-Z (spazi ammessi)",
    "REGISTRATION_PHONE_INVALID":"Il campo telefono deve contenere da 8 a 10 cifre senza spazi o altri caratteri",
    "REGISTRATION_CONFIRM_FEEDBACK_HEADER":"Verifica dati registrazione",
    "REGISTRATION_CONFIRM_FEEDBACK_BACK_TO_HOME":"Ritorna alla homepage",
    "REGISTRATION_ASSOCIATED_QUESTION":"La tua azienda \u00e8 associata a Valore D?",
    "REGISTRATION_QUOTED_QUESTION":"La tua azienda \u00e8 quotata in borsa?",
    "REGISTRATION_FINANCIAL_YEAR_CLOSURE_DATE_MESSAGE":"Seleziona la data di chiusura del tuo bilancio societario (default 31/12)",
    "REGISTRATION_DATE_PICKER_INVALID":"Formato data non valido (il sistema accetta stringhe DD MMMM)",
    "REGISTRATION_AZIENDA_IS_ASSOCIATED_LABEL":"Stato associativo",
    "REGISTRATION_AZIENDA_IS_QUOTED_LABEL":"Stato quotazione",
    "REGISTRATION_COMPANY_TYPE_LABEL":"Tipologia azienda",
    "REGISTRATION_CHANGE_PMI_OPTION": "PMI Italiana (fino a 250 dipendenti, <= 50 milioni euro fatturato annuo)",
    "LOGIN_HEADER":"Accedi al servizio",
    "LOGIN_USERNAME_EMAIL_PLACEHOLDER":"Username oppure email",
    "LOGIN_REMEMBER_ME":"Ricorda per due settimane",
    "LOGIN_BUTTON":"ACCEDI",
    "LOGIN_LABEL": "Accedi",
    "LOGIN_ACCESS_TOOL": "ACCEDI ALLO STRUMENTO",
    "LOGIN_EDIT_REGISTRATION_INFO": "Inserire i dati per la richiesta di registrazione e attendere la conferma via mail.",
    "REGISTER_LABEL": "Registrazione",
    "OR_LABEL": "Oppure",
    "LOGIN_FORGOT_PSW_LINK":"Ho dimenticato la password",
    "LOGIN_NO_ACCOUNT_MESSAGE":"Non hai ancora un account?",
    "LOGIN_REGISTER_LINK":"Richiedi registrazione",
    "LOGIN_FORGOT_PASSWORD_HEADER":"Reset password",
    "LOGIN_FORGOT_PASSWORD_MESSAGE":"Inserisci la tua email per avviare il reset: ",
    "LOGIN_FORGOT_PASSWORD_SUBMIT_BUTTON":"Avvia reset",
    "LOGIN_BACK_TO_HOME_MESSAGE":"Ritorna alla pagina di login",
    "CHANGE_ACCOUNT_SETTINGS_MAIN_HEADER":"Gestisci il tuo account",
    "CHANGE_ACCOUNT_SETTINGS_SEC_HEADER":"Compila i campi che desideri modificare",
    "CHANGE_USERNAME_NEW_LABEL":"Il tuo username :",
    "CHANGE_EMAIL_NEW_LABEL":"Il tuo indirizzo email:",
    "CHANGE_ROLE_NEW_LABEL": "Il tuo ruolo aziendale:",
    "CHANGE_MOBILE_NEW_LABEL":"Il tuo numero telefonico:",
    "CHANGE_CURRENT_LABEL":"Inserisci la password attuale:",
    "CHANGE_NEW_LABEL":"Inserisci una nuova password (minimo 6 caratteri):",
    "CHANGE_REPEAT_NEW_LABEL":"Ripeti la nuova password:",
    "CHANGE_ACCOUNT_SETTINGS_SUBMIT":"Conferma",
    "ACCOUNT_SETTINGS_CURRENT_PASSWORD_ALONE":"Completa anche i campi sottostanti",
    "ACCOUNT_SETTINGS_CURRENT_PASSWORD_VOID":"Devi completare anche il campo password corrente",
    "CHANGE_PSW_SUBMIT":"Cambia password",
    "AZIENDA_DETAIL_SEC_HEADER":"Dati azienda",
    "USER_DETAIL_SEC_HEADER":"Dati account",
    "AZIENDA_DENOM_STRING":"RAGIONE SOCIALE",
    "AZIENDA_P_IVA_STRING":"PARTITA IVA",
    "AZIENDA_SECTOR_STRING":"SETTORE ECONOMICO",
    "AZIENDA_REVENUE_STRING":"FASCIA DI FATTURATO",
    "AZIENDA_IS_QUOTED_STRING": "STATO QUOTAZIONE",
    "AZIENDA_COMPANY_TYPE_STRING": "TIPOLOGIA AZIENDA",
    "AZIENDA_IS_ASSOCIATED_STRING":"STATO ASSOCIATIVO",
    "AZIENDA_EMPLOYEES_RANGE_STRING":"RANGE DIPENDENTI",
    "AZIENDA_DATA_NOT_PROVIDED_STRING":"NON INDICATO",
    "USER_ACCOUNT_USERNAME_STRING":"USERNAME",
    "USER_ACCOUNT_EMAIL_STRING":"INDIRIZZO EMAIL",
    "ENTER_NEW_EMAIL": "Nuovo indirizzo e-mail",
    "USER_COMPANY_ROLE_STRING":"RUOLO AZIENDALE",
    "USER_ACCOUNT_TELEPHONE_STRING":"CONTATTO TELEFONICO",
    "CHANGE_AZIENDA_SETTINGS_MAIN_HEADER":"Gestisci i dati aziendali",
    "UPDATE_YEARLY_AZIENDA_SETTINGS_MAIN_HEADER":"Aggiorna i dati aziendali per l'annualit\u00e0 selezionata",
    "CHANGE_AZIENDA_SETTINGS_SEC_HEADER":"Modifica le attuali impostazioni della tua azienda",
    "CHANGE_DENOMINATION_LABEL":"Nuova ragione sociale:",
    "CHANGE_DENOMINATION_CURRENT":"Ragione sociale attuale \u00e8:",
    "CHANGE_SETTORE_LABEL":"Nuovo settore economico:",
    "CHANGE_SETTORE_CURRENT_SECTOR":"Settore economico attuale \u00e8",
    "CHANGE_CURRENT_ASSOCIATED_OPTION":"Stato associativo corrente",
    "CHANGE_CURRENT_QUOTED_OPTION":"Stato quotazione corrente",
    "CHANGE_FATTURATO_LABEL":"Nuova fascia:",
    "CHANGE_EMPLOYEES_RANGE":"Nuovo range dipendenti:",
    "CHANGE_FATTURATO_CURRENT_SECTOR":"Fascia di fatturato attuale \u00e8",
    "CHANGE_CURRENT_EMPLOYEES_RANGE":"Range dipendenti attuale \u00e8",
    "CHANGE_AZIENDA_SETTINGS_SUBMIT":"Prosegui",
    "CHANGE_AZIENDA_SETTINGS_ALERT_MESSAGE":"Sei sicuro di voler cambiare i tuoi parametri aziendali? Le tue statistiche potrebbero risultare modificate.",
    "CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL" : "Annulla",
    "CHANGE_AZIENDA_SETTINGS_ABORTED_MESSAGE":"Operazione annullata",
    "CHANGE_ASSOCIATED_STATUS_QUESTION":"Nuovo stato associativo: ",
    "CHANGE_COMPANY_TYPE_QUESTION":"Nuova tipologia azienda: ",
    "CURRENTLY_ASSOCIATED_STRING":" IMPRESA ASSOCIATA A VALORE D",
    "CURRENTLY_NOT_ASSOCIATED_STRING":" IMPRESA NON ASSOCIATA A VALORE D",
    "CHANGE_ASSOCIATED_OPTION":"ASSOCIATA",
    "CHANGE_NOT_ASSOCIATED_OPTION":"NON ASSOCIATA",
    "CHANGE_QUOTED_STATUS_QUESTION":"Nuovo stato quotazione: ",
    "CURRENTLY_QUOTED_STRING":" IMPRESA QUOTATA",
    "CURRENTLY_NOT_QUOTED_STRING":" IMPRESA NON QUOTATA",
    "CHANGE_QUOTED_OPTION":"QUOTATA",
    "CHANGE_NOT_QUOTED_OPTION": "NON QUOTATA",
    "CHANGE_PMI_OPTION": "PMI ITALIANA (fino a 250 dipendenti, <= 50 milioni euro fatturato annuo)",
    "CHANGE_SMALL_OPTION": "FILIALE ITALIANA DI UN GRUPPO ESTERO (fino a 250 dipendenti)",
    "CHANGE_LARGE_OPTION": "MULTINAZIONALE, AZIENDA ITALIANA o FILIALE DI UN GRUPPO ESTERO (oltre 250 dipendenti)",
    "REVERT_ICON_TEXT":"Ricarica il valore precedente",
    "SURVEY_INFO_START_ALERT":"Benvenuto!<br><br>Sappiamo che occorrono molti dati per completare l'indagine.<br><br> Ti ringraziamo fin d'ora per l'impegno e ti offriamo la possibilit\u00e0 di salvare anche parzialmente i dati inseriti, uscire dall'applicazione e riprendere la compilazione quante volte desideri.<br><br> Ti ricordiamo che l'accuratezza di ogni dato inserito \u00e8 alla base dell'efficacia dello strumento",
    "SURVEY_OPTIONALITY_MESSAGE":"Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.",
    "SURVEY_MESSAGE_STAFF_LINEA":"I <u>ruoli di linea</u> sono quelli collocati in organi operativi dell'organizzazione (Business Unit, Produzione, Territorio\u2026)<br>I <u>ruoli di staff</u> sono quelli collocati nelle funzioni di supporto dell'organizzazione (Risorse Umane, Finance, Ufficio Legale, Comunicazione, IT...)",
    
    "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> i <i>GRI:</i><br>
        <ul>
            <li>
                <i>2-7 (Dipendenti)</i>.
            </li>
            <li>
                <i>405-1 (Diversit&agrave; negli organi di governo e tra i dipendenti)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                In questa pagina, la somma dei totali di ciascuna sezione deve essere uguale al numero totale dipendenti al punto 1.1 (ad eccezione del punto 1.11).
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    "SURVEY_1_MESSAGE_COMPOSIZIONE_DIPENDENTI_STAFF_LINEA": `
        I <u>ruoli di linea</u> sono quelli collocati negli organi operativi dell'organizzazione (Business Unit, Produzione, Territorio...)<br>
        I <u>ruoli di staff</u> sono quelli collocati nelle funzioni di supporto dell'organizzazione (Risorse Umane, Finance, Ufficio Legale, Comunicazione, IT...).
    `,
    
    "SURVEY_2_3_MESSAGE_COMPOSIZIONE_CDA": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> i <i>GRI:</i><br>
        <ul>
            <li>
                <i>2-9 (Struttura e composizione della governance)</i>.
            </li>
            <li>
                <i>405-1 (Diversit&agrave; negli organi di governo e tra i dipendenti)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire i dati relativi ai consiglieri del CdA tenendo in considerazione tutti i ruoli presenti nell'elenco; per approfondire ciascun ruolo, passare il cursore del mouse sulla "i" di info accanto all'indicatore.
            </li>
            <li>
                Nel caso in cui non fossero presenti consiglieri che ricoprono il ruolo, indicare 0 (zero).
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.
            </li>
        </ul>
    `,
    
    "SURVEY_4_MESSAGE_COMPOSIZIONE_TOP_MANAGEMENT": `
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire al punto 4.1 i dati relativi alla prima linea dell'organizzazione, ovvero tutti i diretti riporti dell'AD, CEO o GM dell'azienda.
            </li>
            <li>
                Inserire ai punti dal 4.2 al 4.26 le informazioni relative al genere dei Direttori nel Top Management; ai punti 4.27 e 4.28 è possibile inserire i dati numerici sui direttori delle BU o di altre direzioni non contemplate sulla lista. Valorizzare <u>solo</u> le direzioni pertinenti all'organizzazione.
            </li>
            <li>
                Se un Direttore dell'organizzazione ricopre più direzioni, allora valorizzare solo una delle due (quella più significativa e di maggiore focus per il Direttore).
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.
            </li>
        </ul>
    `,
    
    "SURVEY_5_MESSAGE_SUCCESSION_PLAN": `
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento al piano di successione relativo all'ultimo anno contabile.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.
            </li>
        </ul>
    `,
    
    
    "SURVEY_6_MESSAGE_COMPOSIZIONE_NUOVI_DIPENDENTI": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-1 (Assunzioni di nuovi dipendenti e avvicendamento dei dipendenti)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                In questa pagina, la somma dei totali di ciascuna sezione deve essere uguale al numero totale nuovi dipendenti al punto 6.1.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle assunzioni avvenute nell'ultimo anno contabile.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.
            </li>
        </ul>
    `,
    
    "SURVEY_7_MESSAGE_TURNOVER_DIPENDENTI": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-1 (Assunzioni di nuovi dipendenti e avvicendamento dei dipendenti)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                In questa pagina, la somma dei totali di ciascuna sezione deve essere uguale al numero totale di turnover al punto 7.1.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle assunzioni avvenute nell'ultimo anno contabile.
            </li>
            <li>
                Il calcolo dei turnover è realizzato attraverso la sommatoria di nuovi entrati (stessi valori di pagina 6) e usciti nell'anno contabile: ad esempio, se sono entrate 10 donne in azienda e sono uscite 5, allora si valorizzerà 15.
            </li>
            
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori.
            </li>
        </ul>
    `,
    
    "SURVEY_8_MESSAGE_COMPOSIZIONE_DIPENDENTI": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>404-1 (Ore medie di formazione annua per dipendente)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle ore di formazione erogate nell'ultimo anno contabile.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alla formazione non obbligatoria (esclusa sicurezza, corruzione...).
            </li>
            <li>
                I dati sulle ore medie devono essere calcolati considerando solo i dipendenti che hanno effettivamente partecipato ad attività di formazione, suddivisi per ciascun inquadramento.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    
    "SURVEY_9_MESSAGE_CONGEDO_PARENTALE": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>401-3 (Congedo parentale)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
               Per congedo parentale si intende il permesso concesso al dipendente per l'assenza dal lavoro nella sua forma facoltativa, integrativa rispetto a quella prevista dalla legge.
            </li>
            <li>
                Il congedo parentale è previsto per tutti i dipendenti che hanno almeno un figlio/a di et&agrave; uguale o inferiore ai 12 anni.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento ai permessi per congedo parentale concessi nell'ultimo anno contabile.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    "SURVEY_9_MESSAGE_CONGEDO_PARENTALE_PER_GENERE": `
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                per numero massimo si intende il maggior numero di giorni concessi ad una/un dipendente.
            </li>
            <li>
                per numero medio si intende la media dei giorni concessi a tutti i dipendenti per ciascun genere.
            </li>
            <li>
                per numero minimo si intende il minor numero di giorni concessi ad una/un dipendente.
            </li>
        </ul>
        
        Esempi<br>
        Dipendente #1 = 3 giorni concessi, Dipendente #2 = 50 giorni concessi, Dipendente #3 = 60 giorni concessi.<br>
        Numero massimo = 60 giorni (quelli del Dipendente #3).<br>
        Numero medio = media tra 60/50/3 = 37.6 giorni.<br>
        Numero minimo = 3 giorni (quelli del Dipendente #1).
    `,
    
    "SURVEY_10_MESSAGE_AVANZAMENTO_CARRIERA": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>404-3 (Percentuale di dipendenti che ricevono una valutazione periodica delle performance e dello sviluppo professionale)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle promozioni relative all'ultimo anno contabile.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    
    "SURVEY_11_MESSAGE_DIFFERENZE_RETRIBUTIVE": `
        Le informazioni contenute in questa sezione riguardano <b>in parte</b> il <i>GRI:</i><br>
        <ul>
            <li>
                <i>405-2 (Rapporto dello stipendio base e retribuzione delle donne rispetto agli uomini)</i>.
            </li>
        </ul>
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                La Retribuzione Annuale Lorda (RAL) è composta da minimo contrattuale, scatto d'anzianità, contingenza, superminimo e assegno supplementare.
            </li>
            <li>
                La Retribuzione Totale Annua (RTA) è composta da RAL, retribuzione variabile (bonus e incentivi) e benefit aziendali.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle retribuzioni relative all'ultimo anno contabile.
            </li>
            <li>
                Tutti i valori espressi in questa sezione sono in Euro (€).
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    
    "SURVEY_12_MESSAGE_FORME_DI_LAVORO_FLESSIBILE": `
        <b><u>INFORMAZIONI PER LA COMPILAZIONE</u></b><br>
        <ul>
            <li>
                Inserire solo i dati relativi ai dipendenti che hanno un contratto diretto con l'organizzazione (no somministrati, no contingent).
            </li>
            <li>
                Inserire le percentuali relative all'utilizzo delle forme di lavoro flessibile suddivise per generi, calcolate sul totale dei singoli generi.
            </li>
            <li>
                I dati inseriti in questa sezione devono fare riferimento alle pratiche relative all'ultimo anno contabile.
            </li>
            <li>
                Gli indicatori contrassegnati da asterisco (<span class='required'></span> ) sono campi obbligatori. Se non si intende inserire numeri per gli indicatori facoltativi, allora lasciare vuote le celle.
            </li>
        </ul>
    `,
    
    "SURVEY_PLUS_1_MESSAGE_INTRODUCTION":"<u>INTRODUZIONE</u><br>\r\n <ul><li>Inserisci le informazioni in riferimento a un determinato anno fiscale, selezionandolo attraverso il tasto in alto allo schermo.</li><li>Riporta solo le politiche e iniziative in essere durante l'anno fiscale di riferimento, non quelle previste o ipotizzate in futuro.</li><li>Inserisci le informazioni in riferimento al perimetro territoriale italiano, salvo dove diversamente indicato.</li><li>È possibile che alcune domande richiedano il confronto con colleghi di altri dipartimenti per reperire informazioni concrete e reali.</li><li>Le domande prevedono una risposta singola, salvo dove diversamente indicato.</li></ul>",
    "SURVEY_MEN_STRING":"UOMINI",
    "SURVEY_WOMEN_STRING":"DONNE",
    "SURVEY_YES_STRING":"S\u00ec",
    "SURVEY_NO_STRING":"No",
    "SURVEY_SAVE_AND_CONTINUE_BUTTON":"Salva modifiche e continua",
    "SURVEY_WOMAN_STRING":"Donna",
    "SURVEY_MAN_STRING":"Uomo",
    "SURVEY_INTEGER_FIELD_PLACEHOLDER":"Inserisci un valore intero",
    "SURVEY_PERCENTAGE_FIELD_PLACEHOLDER":"Inserisci un valore percentuale",
    "SURVEY_TEXTUAL_FIELD_PLACEHOLDER":"Inserisci testo",
    "SURVEY_TOTAL_STRING":"TOTALE",
    "SURVEY_CONSTRAINING_ERROR":"Il valore inserito \u00e8 superiore a quello del campo",
    "SURVEY_GENDER_FIELD_INCOHERENCY":"Il valore totale \u00e8 diverso dalla somma dei parziali. Prego correggere l'errore\n",
    "SURVEY_LAST_MODIFIED_BY":"Valore inserito utente",
    "SURVEY_RESET_VALUE":"Cancella",
    "SURVEY_TIME_LAST_MODIFICATION":"il",
    "SURVEY_FULLY_OPTIONAL_SET":"La compilazione \u00e8 facoltativa ma FORTEMENTE CONSIGLIATA",
    "SURVEY_FORMATION_HOURS_CONSTRAINING_ERROR":"Inserisci un valore PRO CAPITE, non il totale riguardante tutti gli impiegati",
    "SURVEY_FOREIGN_CDA_CONFIRMATION MESSAGE":"Sei sicuro che il vostro CDA non ha sede in Italia? Se confermi la risposta non avrai la possibilit\u00e0 di rispondere ad alcune domande importanti e il tuo report finale sar\u00e0 meno attendibile",
    "SURVEY_PARTIALLY_UNFILLED_INCOHERENCY":"Questo campo non pu\u00f2 essere lasciato vuoto se \u00e8 stato gi\u00e0 fornito un valore dello stesso gruppo",
    "SURVEY_BREADCRUMB_TEXT":"Torna all'homepage",
    "SURVEY_GO_BEHIND_BUTTON":"Indietro",
    "SURVEY_GO_AHEAD_BUTTON":"Avanti",
    "SURVEY_LAST_SET_SUBMIT_BUTTON":"Salva e termina il questionario",
    "SURVEY_SUMMARY_GENDER_STRING":"GENERE",
    "SURVEY_SUMMARY_ANSWER_STRING":"RISPOSTA",
    "SURVEY_SUMMARY_TITLE":"Riepilogo dati inseriti - Annualit\u00e0 fiscale ",
    "SURVEY_VOID_STRUCTURE_SUMMARY_TITLE":"Struttura del questionario",
    "SURVEY_STRUCTURE_SUMMARY_GENDER_ALTERNATIVES":"(DONNA/UOMO)",
    "SURVEY_STRUCTURE_SUMMARY_YES_NO_ALTERNATIVES":"(S\u00cc/NO)",
    "SURVEY_SUMMARY_PRINT_BUTTON":"Stampa o scarica i dati",
    "SURVEY_INFO_READONLY_SET_ALERT":"Questo \u00e8 un set di domande di sola lettura. Prima di poterlo compilare devi completare tutti i set precedenti",
    "DASHBOARD_TEST_HEADER":"COMPILA <br> L'INDAGINE",
    "DASHBOARD_FIRST_SURVEY_MESSAGE":"Inizia la compilazione del tuo questionario!",
    "DASHBOARD_MODIFY_SURVEY_MESSAGE":"Modifica i dati inseriti nel questionario",
    "DASHBOARD_SURVEY_BUTTON":"Vai al questionario",
    "DASHBOARD_REPORT_HEADER":"VALUTA LA TUA <br> POLITICA D&I",
    "DASHBOARD_REPORT_MESSAGE":"Visualizza score report dettagliato",
    "DASHBOARD_REPORT_BUTTON":"Scopri tutti i dettagli",
    "DASHBOARD_III_HEADER":"Il vostro risultato attuale \u00e8:",
    "DASHBOARD_REPORT_TIMESTAMP_MESSAGE":"Registrato il: ",
    "DASHBOARD_YEARLY_PERIOD_MESSAGE":"Anno Fiscale",
    "DASHBOARD_SELECT_YEAR_INCOMPLETE":"(DATI INCOMPLETI)",
    "DASHBOARD_YEARLY_PARAMETERS_UPDATE_POPUP_TITLE":"AGGIORNA LE IMPOSTAZIONI PER L'ANNUALIT\u00c0 FISCALE",
    "DASHBOARD_NEW_YEAR_AVAILABLE_NOTIFICATION":"Benvenuto!<br>Ti informiamo che rispetto alla tua ultima visita \u00e8 ora possibile inserire i dati per il nuovo anno fiscale.<br> Controlla il men\u00f9 di selezione dell\u2019annualit\u00e0 fiscale.",
    "HOMEPAGE_MESSAGE":"<p>L'<b>Inclusion Impact Index</b> \u00e8 uno strumento diagnostico online che sintetizza in maniera chiara e semplice a che punto \u00e8 un'azienda rispetto ai <b>temi della diversit\u00e0 e dell'inclusione</b>, sulla base degli indicatori GRI. In particolare, questo strumento fornisce un benchmark di settore rispetto alla governance, alla capacit\u00e0 di attrarre, sviluppare e trattenere i talenti in ottica D&I. I dati inseriti prendono in esame solo l\u2019Italia. I dati inseriti sono destinati ad uso interno dell\u2019azienda compilante. <b>Valore D tratta i dati in forma anonima e aggregata.</b> <p>",
    "META_DESCRIPTION":"L'Inclusion Impact Index \u00e8 uno strumento diagnostico online che sintetizza in maniera chiara e semplice a che punto \u00e8 un'azienda rispetto ai temi della diversit\u00e0 e dell'inclusione, sulla base degli indicatori GRI.",
    "HEADER_REGISTER_BUTTON":"REGISTRAZIONE",
    "HEADER_MY_ACCOUNT_BUTTON":"IL MIO ACCOUNT",
    "HEADER_EDIT_ACCOUNT_SETTINGS_BUTTON":"Gestisci account",
    "HEADER_VOID_SURVEY_STRUCTURE_GENERATOR":"Stampa struttura questionario",
    "USER_DETAIL_MAIN_HEADER":"Dettagli account",
    "HEADER_EDIT_AZIENDA_SETTINGS_BUTTON":"Gestisci dati aziendali",
    "HEADER_ACCOUNT_DATA_SUMMARY_BUTTON":"Dettagli account",
    "USER_CHANGE_EMAIL_DIALOGUE_BOX_MESSAGE":"La tua richiesta di cambio email \u00e8 stata correttamente presa in carico<br>Clicca sul link che abbiamo inviato al nuovo indirizzo per completare l'operazione.",
    "USER_RESET_PASSWORD_DIALOGUE_BOX_MESSAGE":"La tua richiesta di reset password \u00e8 stata correttamente presa in carico<br>Clicca sul link che abbiamo inviato al nuovo indirizzo per completare l'operazione.",
    "REPORT_III_RESULT_HEADER":"IL VOSTRO RISULTATO COMPLESSIVO \u00c8:",
    "REPORT_III_GENERATION_MIX_HEADER": "IL VOSTRO GENERATION MIX:",
    "REPORT_KPI_RESULTS_HEADER":"GLI INDICATORI NEL DETTAGLIO: ",
    "REPORT_AVERAGE_VALUE_STRING":"VALORE MEDIO AZIENDE",
    "REPORT_EMPLOYEES_COMPARISON_BUTTON":"CONFRONTA PER NUMERO DIPENDENTI",
    "REPORT_SECTOR_COMPARISON_BUTTON":"CONFRONTA PER SETTORE ECONOMICO",
    "REPORT_FATTURATO_COMPARISON_BUTTON":"CONFRONTA PER FATTURATO",
    "REPORT_III_UNDER_AVERAGE_FEEDBACK":"PUOI FARE DI MEGLIO! SCOPRI QUALI SONO LE TUE AREE DI MIGLIORAMENTO <br>",
    "REPORT_III_IN_THE_AVERAGE_FEEDBACK":"IL VOSTRO PUNTEGGIO \u00c8 IN LINEA CON LA MEDIA GENERALE<br>",
    "REPORT_III_OVER_AVERAGE_FEEDBACK":"COMPLIMENTI! STAI FACENDO UN OTTIMO LAVORO. CONTINUA COS\u00cc!<br>",
    "REPORT_COMPARISON_NO_ROWS_RETRIEVED":"Il database non contiene nessun report aziendale con le caratteristiche selezionate",
    "REPORT_COMPARISION_CHART_TITLE_SETTORE":"Confronto dei tuoi risultati con quelli ottenuti dalle aziende del settore ",
    "REPORT_COMPARISION_CHART_TITLE_FATTURATO":"Confronto dei tuoi risultati con quelli ottenuti dalle aziende con fatturato ",
    "REPORT_COMPARISION_CHART_TITLE_EMPLOYEES":"Confronto dei tuoi risultati con quelli ottenuti dalle aziende con ",
    "REPORT_SETTORE":"",
    "REPORT_FATTURATO":"",
    "REPORT_EMPLOYEES":"dipendenti",
    "REPORT_SELECT_EMPLOYEES_LABEL":"Confronta con la fascia di dipendenti: ",
    "REPORT_SELECT_SETTORE_LABEL":"Confronta con il settore: ",
    "REPORT_SELECT_FATTURATO_LABEL":"Confronta con la fascia di fatturato: ",
    "REPORT_INDEX_CHART_AVG_TOOLTIP_LABEL":"Risultato medio indice",
    "REPORT_INDEX_CHART_YOUR_SCORE_TOOLTIP_LABEL":"Risultato impresa",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_SECTOR":"Media del settore",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_REVENUE":"Media con simile fatturato",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_EMPLOYEES":"Media fascia dipendenti",
    "REPORT_BONUS_PAYGAP_MESSAGE_FIRST_PART":"HAI GUADAGNATO ",
    "REPORT_BONUS_PAYGAP_MESSAGE_LAST_PART":" PUNTI EXTRA PER LA TUA TRASPARENZA CONTRO LE DISCRIMINAZIONI SALARIALI",
    "REPORT_COMPARISON_ALL_EMPLOYEES_RANGES":"TUTTE LE FASCE DI DIPENDENTI",
    "REPORT_COMPARISON_ALL_SECTORS":"TUTTI I SETTORI",
    "REPORT_COMPARISON_ALL_REVENUES":"TUTTE LE FASCE DI FATTURATO",
    "REPORT_SELECT_QUOTED_LABEL":"Confronta con le imprese quotate: ",
    "REPORT_SELECT_ASSOCIATED_LABEL":"Confronta con le imprese associate a Valore D: ",
    "REPORT_SELECT_COMPANY_TYPE":"Confronta con la tipologia d'impresa:",
    "REPORT_COMPARISON_INDIFFERENT":"INDIFFERENTE",
    "REPORT_COMPARISON_QUOTED_MESSAGE":"SOLO IMPRESE QUOTATE",
    "REPORT_COMPARISON_NOT_QUOTED_MESSAGE":"SOLO IMPRESE NON QUOTATE",
    "REPORT_COMPARISON_ASSOCIATED_MESSAGE":"SOLO IMPRESE ASSOCIATE A VALORE D",
    "REPORT_COMPARISON_NOT_ASSOCIATED_MESSAGE":"SOLO IMPRESE NON ASSOCIATE A VALORE D",
    "REPORT_COMPARISON_SECTION_HEADER":"Confronta i vostri risultati: ",
    "REPORT_CURRENT_DETAILS_PAGE_AS_PDF":"Stampa o scarica il report",
    "REPORT_COMPARISON_CHART_TITLE_WITH_COMPANY_#":"Numero di imprese incluse nel confronto: ",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP":"Valore medio selezione",
    "REPORT_COMPARISON_CHART_AVG_TOOLTIP_HISTORICAL":"Valore medio selezione per il relativo anno fiscale",
    "REPORT_OPEN_PROVIDED_DATA_SUMMARY_BUTTON":"Riepilogo dati inseriti",
    "REPORT_INDEX_RESULTS_FIXED_GOVERNANCE_DESCRIPTION":"Quest'area riflette la capacit\u00e0 dell'impresa di fare crescere le donne al proprio interno, fino ai ruoli apicali.",
    "REPORT_INDEX_RESULTS_FIXED_ATTRACTIVENESS_DESCRIPTION":"Quest'area riflette la capacit\u00e0 dell'impresa di attrarre talenti femminili e di trasmettere quindi i propri valori e programmi rispetto ai\u00a0temi della D&I.",
    "REPORT_INDEX_RESULTS_FIXED_DEVELOPMENT_DESCRIPTION":"Quest'area riflette la capacit\u00e0 dell'impresa di valorizzare i talenti femminili gi\u00e0 presenti al proprio interno, definendo percorsi di crescita personalizzati per supportare il merito e il talento.",
    "REPORT_INDEX_RESULTS_FIXED_RETAINMENT_DESCRIPTION":"Quest'area riflette la capacit\u00e0 dell'impresa di valorizzare i talenti femminili in maniera integrata e continuativa durante tutta la vita professionale di una donna.",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_GOVERNANCE":"Complimenti! La tua impresa sta facendo un ottimo lavoro! Nella tua organizzazione sapete valorizzare il talento femminile fino ai vertici.",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_GOVERNANCE":"La tua impresa pu\u00f2 migliorare la valorizzazione dei talenti femminili al proprio interno. <br>\r\n <b>Perch\u00e9?</b> Perch\u00e9 le aziende con management team pi\u00f9 diversificati ottengono migliori performance in termini finanziari. Infatti il margine EBITDA aumenta di 2 punti percentuali e il CFROI aumenta di 2.04 punti percentuali nelle aziende in cui le donne ricoprono il 20% di ruoli nel senior management rispetto alle aziende con il 15% o meno di donne nella stessa posizione. * <br>\r\n <b>Come fare?</b> Con programmi dedicati alle top manager, come il percorso C-Level di Valore D e i programmi di Mentorship.<br><br>\r\n <i>* Fonte: Credit Suisse, Gender 3000, 2019.</i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_ATTRACTIVENESS":"Complimenti! La tua impresa sta facendo un ottimo lavoro! Le giovani donne guardano a voi come un'impresa attenta a questi temi e voi le sapete valorizzare fin dall'ingresso al lavoro",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_ATTRACTIVENESS":"La tua impresa pu\u00f2 migliorare la capacit\u00e0 di attrarre talenti al femminile. <br>\r\n <b>Perch\u00e9?</b> Perch\u00e9 avere una ottima reputazione incide sul desiderio di lavorare per il brand, infatti il 70% dei consumatori \u00e8 propenso a lavorare in un'azienda con una eccellente reputazione, contro il 9% delle societ\u00e0 con scarsa reputazione. ** <br>\r\n <b>Come fare?</b> Sfruttando a pieno le attivit\u00e0 proposte da Valore D e dando visibilit\u00e0 al vostro impegno. \u00c8 possibile creare progetti ad hoc di sensibilizzazione nelle scuole e piani di crescita per le nuove generazioni.<br> Valore D ti pu\u00f2 supportare con i progetti InspirinGirls e il percorso di sviluppo dedicato.<br><br>\r\n <i>**Fonte: Reputation Institute, RepTrack Report, 2020.</i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_DEVELOPMENT":"Complimenti! La tua impresa sta facendo un ottimo lavoro. <br> State incoraggiando le giovani donne ad esprimere tutto il proprio potenziale sul posto di lavoro!",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_DEVELOPMENT":"La tua impresa pu\u00f2 migliorare la capacit\u00e0 di valorizzare merito e talento femminili gi\u00e0 presenti al proprio interno.<br>\r\n <b>Perch\u00e9?</b> Perch\u00e9 il percorso di carriera delle donne \u00e8 come un tubo che perde: nel settore privato le donne rappresentano in media il 44,1% degli impiegati, il 32,8% dei quadri e solo il 18,6% dei dirigenti. *** <br>\r\n <b>Come agire?</b> Potenziando le hard e le soft skills. <br> Valore D ti pu\u00f2 supportare con il percorso di sviluppo dedicato e i programmi di Mentorship.<br><br>\r\n <i>*** Inclusion Impact Index\u00ae, dati aggregati, 2020.</i>",
    "REPORT_INDEX_RESULTS_FEEDBACK_GOOD_RETAINMENT":"Complimenti! La tua impresa sta facendo un ottimo lavoro!<br> Le donne all'interno dell'organizzazione continuano ad essere coinvolte e motivate.",
    "REPORT_INDEX_RESULTS_FEEDBACK_BAD_RETAINMENT":"La tua impresa pu\u00f2 migliorare la capacit\u00e0 di continuare a valorizzare merito e talento femminili nel tempo. <br>\r\n <b>Perch\u00e9?</b> Perch\u00e9 con l'innalzamento dell'et\u00e0 pensionabile anche le donne resteranno di pi\u00f9 al lavoro,<br> ed \u00e8 fondamentale che continuino ad essere coinvolte e motivate, evitando di portare all'esterno il patrimonio conoscitivo dell'azienda.<br>\r\n <b>Come fare?</b> Valore D ti pu\u00f2 supportare con percorsi di sviluppo innovativi e anche con la condivisione di buone pratiche, come ad esempio sulla genitorialit\u00e0 condivisa, sulla riduzione del gender pay gap e sulle modalit\u00e0 di lavoro flessibile.",
    "REPORT_GOVERNANCE_SKIPPED_ALERT_MESSAGE":"Hai dichiarato che la tua azienda non ha il CdA in Italia.<br> Per questo motivo non \u00e8 possibile rappresentare il risultato del KPI Governance ma il punteggio finale viene calcolato senza penalizzare la mancanza di CdA in Italia, tenendo in considerazione gli altri dati sulle donne in posizioni apicali.",
    "REPORT_INDEX_SKIPPED_ALERT_MESSAGE":"I dati forniti non sono sufficienti per calcolare in modo affidabile uno o pi\u00f9 indicatori di D&I<br>Questi risultati saranno contrassegnati come 'N.A.' e nei diagrammi sar\u00e0 rappresentato un valore nullo al loro posto",
    "REPORT_ALERT_MESSAGE":"ATTENZIONE",
    "REPORT_HISTORIC_SELECT_LABEL":"Confronta con lo storico dei risultati:",
    "REPORT_COMPARISON_HISTORICAL_MODE_ACTIVE_MESSAGE":"TUTTE LE ANNUALIT\u00c0",
    "REPORT_COMPARISON_HISTORICAL_MODE_INACTIVE_MESSAGE":"SOLO ANNO FISCALE CORRENTE",
    "SAVE_CONTINUE": "Salva e Continua",
    "EMAIL_CONFIRMATION": "Verifica email",
    "EMAIL_VERIFICAION_DESCRIPTION": "Fare click sul bottone sottostante per verificare il tuo indirizzo email.",
    "VERIFY_EMAIL_ADDRESS": "Verifica Email",
    "RESEND_EMAIL_VERIFICATION" : "Rinvia ",
    "BUSINEESS_NAME" : "BUSINESS NAME",
    "LAST_SECTOR" : "LAST SECTOR",
    "VAT_NUMBER" : "Partita IVA",
    "CREATION_DATE" : "CREATION DATE",
    "USER_NAME" : "USER NAME",
    "EMAIL" : "EMAIL",
    "ACTIVE" : "ACTIVE",
    "Actions" : "Actions",
    "SEARCH_USERS" : "Ricerca Utenti",
    "VAT_NUMBER_REQUIRED" : "Partita IVA è un campo obbligatorio.",
    "LEGAL_ENTITY_REQUIRED" : "Ragione sociale è un campo obbligatorio.",
    "INDUSTRIAL_SECTOR_REQUIRED" : "Settore economico è un campo obbligatorio.",
    "REVENUE_RANGE_REQUIRED" : "Fascia di fatturato è un campo obbligatorio.",
    "USERNAME_REQUIRED" : "Nome utente è un campo obbligatorio.",
    "EMAIL_REQUIRED" : "Email è un campo obbligatorio.",
    "EMAIL_OR_USERNAME_REQUIRED" : "Email o username è un campo obbligatorio.",
    "EMAIL_CONFIRMATION_REQUIRED" : "Conferma email è un campo obbligatorio.",
    "PASSWORD_REQUIRED" : "Password è un campo obbligatorio.",
    "PASSWORD_CONFIRMATION_REQUIRED" : "Conferma password è un campo obbligatorio.",
    "MEMBERSHIP_REQUIRED" : "Stato associativo è un campo obbligatorio.",
    "LISTING_REQUIRED": "Stato quotazione è un campo obbligatorio.",
    "COMPANY_TYPE_REQUIRED" : "Tipologia azienda è un campo obbligatorio.",
    "RESET_PASSWORD_PAGE_TITLE": "Reimposta Password - Inclusion Impact Index Plus",
    "HOMEPAGE_PAGE_TITLE": 'Home - Inclusion Impact Index Plus',
    "AUTH_PAGE_TITLE": 'Login - Inclusion Impact IndexPlus',
    "VALID_EMAIL" : "Email deve essere valida",
    "PASSWORD_LENGTH_VALIDATION" : "Password deve essere più di  6 caratteri.",
    "PASSWORD_MUST_MATCH" : "Le password devono essere uguali",
    "SIGNUP_PAGE_TITLE": "Registrati - Inclusion Impact Index Plus",
    "SIGNIN_PAGE_TITLE": "Autenticazione - Inclusion Impact Index Plus",
    "EMAIL_MUST_MATCH" : "Le email devono essere uguali",
    "BUTTON_DISCARD_CHANGES" : "Annulla",
    "BUTTON_SAVE_CHANGES" : "Salva le modifiche",
    "LABEL_USER_PROFILE" : "Profilo Utente",
    "BUTTON_CHANGE_EMAIL" : "Cambia Email",
    "BUTTON_CHANGE_PASSWORD" : "Cambia Password",
    "FEEDBACK_PASSWORD_RESET_FAIL": "Non è possible reimpostare la password.",
    "BUTTON_SUBMIT" : "Invia",
    "BUTTON_WAIT" : "Aspetta...",
    "COMPANY_NAME_REQUIRED" : "Ragione sociale è un campo obbligatorio.",
    "EMPLOYEE_RANGE_REQUIRED" : "Range dipendenti è un campo obbligatorio.",
    "USERNAME_CHARACTER_VALIDATION" : "Il nome utente deve essere la combinazione di lettere e numeri, da 2 a 64 caratteri.",
    "SIGN_OUT": "Esci",
    "UPDATE_LOGIN_CREDENTIAL":"Modifica credenziali di accesso",
    "ACTIVE_LABEL": "Attiva",
    "INACTIVE_LABEL": "Disattiva",
    "CONTACT_US_LABEL": "Contattaci",
    "CONTINUE": "Continua",
    "BACK": "Indietro",
    "BUTTON_RESULT": "Vedi i Risultati",
    "BUTTON_MODIFICATION": "Modifica",
    "TITLE_CURRENT_RESULT": "Il vostro risultato attuale",
    "TITLE_SURVEY_PLUS_OVERALL_RESULT": "Il vostro risultato Inclusion Plus",
    "TITLE_SURVEY_PLUS_PIE_RESULT": "Il vostro risultato nel dettaglio",
    "SURVEY_PLUS_LABEL_NUMBER_OF_COMPARISON": "Numero di imprese incluse nel confronto ",
    "SURVEY_PLUS_LABEL_DIVERSITY": "Diffusione",
    "SURVEY_PLUS_LABEL_POLICY": "Ampiezza",
    "SURVEY_PLUS_LABEL_DIVERSITY_DETAIL": "Ampiezza delle pratiche e politiche",
    "SURVEY_PLUS_LABEL_POLICY_DETAIL": "Diffusione delle diversità",
    "SURVEY_PLUS_LABEL_YOUR_COMPANY":"La vostra azienda",
    "SURVEY_PLUS_LABEL_AVERAGE_COMPANIES":"Media aziende",
    "SURVEY_PLUS_HOW_TO_READ": "Come si leggono questi dati",
    "SURVEY_PLUS_HOW_TO_READ_DETAIL": "<strong>AMPIEZZA</strong><br>È il numero che indica la capacità dell'azienda di lavorare sulla DEI e i suoi principi all’interno di tutti i processi presenti nell'organizzazione.<br><br><strong>DIFFUSIONE</strong><br>È il numero che indica la capacità dell’azienda di realizzare azioni rispetto a più tipi di diversità.<br><br><strong>FREQUENZA</strong><br>È la misura che indica l’impegno delle aziende nell’includere ciascun tipo di diversità nei processi organizzativi.<br><br>Tutte le domande del questionario contribuiscono alla misurazione dell’ampiezza, mentre solo alcune domande fanno un approfondimento rispetto alle sei diversità.<br><br>Quindi il peso di ciascuna sezione ha un impatto differente sul risultato complessivo dell’Inclusion Plus.",
    "LABEL_COMPARE_SURVEY_PLUS_CHART": "Confronta i vostri risultati",
    "LABEL_COMPARE_YEAR_SURVEY_PLUS_CHART": "Confronta con lo storico anno",
    "TITLE_SURVEY_PLUS_RADAR_RESULT": "La frequenza delle sei diversità",
    "SURVEY_PLUS_LABEL_GENRE":"Genere",
    "SURVEY_PLUS_LABEL_AGE":"Età / generazioni",
    "SURVEY_PLUS_LABEL_DISABILIY":"Disabilità / abilità",
    "SURVEY_PLUS_LABEL_CULTURE":"Cultura",
    "SURVEY_PLUS_LABEL_BACKGROUND":"Background",
    "SURVEY_PLUS_LABEL_SEX":"Orientamento sessuale",
    "THEADER_COMPANY_SURVEY_PLUS_RADAR_DETAIL": "Vostra azienda",
    "THEADER_RESULT_SURVEY_PLUS_RADAR_DETAIL": "Risultati",
    "TITLE_SURVEY_PLUS_RADAR_DETAILS": "Dati dettagli",
    "SMALL_TITLE_SURVEY_PLUS_RADAR_DETAILS": "Confrontare",
    "TEXT_NO_DESCRIPTION": "Non c'è descrizione",
    "TITLE_YEAR": "Anno fiscale",
    "BUTTON_CLOSE": "Chiudi",
    "SAVE_FINSH": "Salva e termina",
    "FIELD_REQUIRED" : "Questo campo è obbligatorio!",
    "FIELD_SHOULD_BE_NUMBER" : "Questo campo dovrebbe essere il numero!",
    "FIELD_SHOULD_BE_INTEGER" : "Questo campo dovrebbe essere un numero intero!",
    "REGISTRATION_PROCESS":"Vuoi procedere con la richiesta di registrazione?",
    "SURVEY_ERROR_MESSAGE" : "Abbiamo trovato un errorre durante la fase di compilazione del form. Verificare la correttezza dei valori, quindi procedere nuovamente al salvataggio.",
    "SELECT_ONE_OPTION": "Seleziona almeno un'opzione!",
    "SELECT_AN_OPTION": "Seleziona un'opzione!",
    "REPORT_III_GENERATION_MIX_LABEL": "Distribuzione popolazione aziendale per genere e fascia di età",
    "INCONSISTENT_DATA_TOOLTIP_LABEL": "Dato incoerente nel questionario",
    "WOMEN_BY_AGO_RANGE": "Donne per Fascia di Età",
    "MEN_BY_AGO_RANGE": "Uomini per Fascia di Età",
    "NEW_EMPLOYEES_LABEL": "DIPENDENTI ENTRANTI",
    "EXITED_EMPLOYEES": "DIPENDENTI USCITI",
    "REPORT_INCOMPLETED_YEAR_REDIRECT_MESSAGE": "Per poter visualizzare questa pagina occorre compilare i dati aziendali per l'annata ",
    "NOT_CHANGE_YEAR_MESSAGE": "Per poter visualizzare questa pagina occorre compilare i dati aziendali per l'annata ",
    "AGREE_REQUIRED" : "Dovresti accettare  i termini di privacy",
    "III_PLUS_LOGO":"Logo Inclusion Impact Index Plus",
    "III_PLUS_COMPACTED_LOGO":"Logo Inclusion Impact Index Plus compattato",
    "VALORED_PROJECT_LOGO":"Un progetto di Valore D",
    "LANDING_HOW_MUCH_INCLUSIVE" :"<p style='line-height: normal;'>Quanto<br>è inclusiva<br>la tua azienda?</p>",
    "LANDING_DISCOVER_III":"Scoprilo con <nobr>l'Inclusion Impact Index Plus</nobr>, lo strumento che abbiamo progettato per tutte le aziende che vogliono mappare e misurare le politiche di <span class=\"text-yellow\"> Diversità</span>, <span class=\"text-light-pink\"> Equità</span> e <span class=\"text-custom-cyan\">Inclusione</span>.",
    "LANDING_MORE_OPPORTUNITIES":"Più opportunità per te.",
    "LANDING_EASY_AND_SIMPLE_TO_USE":"Gratuito e semplice da usare",
    "LANDING_EASY_AND_SIMPLE_TO_USE_DETAIL":"Compila il questionario, salva i progressi e completalo quando vuoi.",
    "LANDING_COMPARE_WITH_OTHER_COMPANY":"Confronta con altre aziende",
    "LANDING_COMPARE_WITH_OTHER_COMPANY_DETAIL":"Scopri come si posizio na la tua azienda rispetto alle altre organizzazioni.",
    "LANDING_COMPLETE_OVERVIEW":"Panoramica completa",
    "LANDING_COMPLETE_OVERVIEW_DETAIL":"Con i report personalizzati individui le aree di forza e quelle su cui l'azienda potrebbe migliorare.",
    "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION":"Propedeutico alle certificazioni",
    "LANDING_COMPLETE_PREPARATION_FOR_CERTIFICATION_DETAIL":"Confronta il risultato rispetto alle richieste della prassi di riferimento UNI 125:2022.",
    "LANDING_HOW_IT_WORKS":"Come funziona?",
    "LANDING_HOW_IT_WORKS_REGISTER":"Registrati sulla piattaforma per ottenere l'accesso all'area riservata.",
    "LANDING_HOW_IT_WORKS_FILL_IN_INFO":"Compila i questionari con la libertà di salvare l'avanzamento e riprendere quando vuoi.",
    "LANDING_HOW_IT_WORKS_COMPARE":"Consulta il risultato e confrontalo con il benchmark.",
    "LANDING_EVEN_MORE_POWERFUL":"Ancora più potente, ancora più inclusivo.",
    "LANDING_EVEN_MORE_POWERFUL_DETAIL":"Compilando i due questionari ottieni due report personalizzati che abbracciano proprio tutte le diversità.",
    "LANDING_EVEN_MORE_POWERFUL_QUANTITATIVE_REPORT":"Report Quantitativo",
    "LANDING_EVEN_MORE_POWERFUL_QUANTITATIVE_REPORT_DETAIL":"Raccoglie e analizza tutti i numeri della tua organizzazione sugli equilibri di genere e generazionali sulla base degli indicatori che rispondono all’obbligo di comunicazione di informazioni di carattere non finanziario (d.lgs. 30 dicembre 2016, n. 254).",
    "LANDING_EVEN_MORE_POWERFUL_QUALITATIVE_REPORT":"Report Qualitativo",
    "LANDING_EVEN_MORE_POWERFUL_QUALITATIVE_REPORT_DETAIL":"Raccoglie tutte le iniziative e le politiche relative alle sei tipologie di diversità presenti in azienda e restituisce il posizionamento della tua organizzazione sul tema.",
    "LANDING_III_PLUS":"Inclusion Impact Index Plus",
    "LANDING_III_PLUS_DETAIL":"Moltiplica le opportunità per creare un mondo del lavoro più inclusivo.",
    "LANDING_VIDEO": "Video di spiegazione riguardo lo strumento Inclusion Impact Index Plus",
    "DASHBOARD_III_DESCRIPTION":"La sezione quantitativa <label class=\"text-red\">Inclusion Impact Index</label> raccoglie e analizza tutti i numeri dell'organizzazione sugli equilibri di genere e generazionali sulla base degli indicatori che rispondono all’obbligo di comunicazione di informazioni di carattere non finanziario (d. lgs 30 dicembre 2016, n. 254). ",
    "DASHBOARD_PLUS_DESCRIPTION":"La sezione qualitativa <label class=\"text-custom-cyan\">Inclusion Plus</label> raccoglie tutte le iniziative  e le politiche relative alle sei tipologie di diversità presenti in azienda e restituisce il posizionamento dell'organizzazione sul tema. ",
    "DASHBOARD_BUTTON_START_THE_SURVEY": "Inizia il questionario",
    "DASHBOARD_BUTTON_CONTINUE_THE_SURVEY": "Continua il questionario",
    "LABEL_NUMBER_OF_COMPANY" : "Numero di imprese incluse nel confronto",
    "SURVEY_BUTTON_DOWNLOAD_EMPTY_SURVEY":"Scarica questionario vuoto",
    "SURVEY_BUTTON_DOWNLOAD_SURVEY_WITH_DATA":"Scarica questionario compilato",
    "OPTIONAL":"Opzionale",
    "REGISTER_NOW":"Sì, registrami ora",
    "REGISTER_CANCEL_BUTTON":"Cancella",
    "REFUSE_LABEL": "Rifiuta",
    "PASSWORD_SUCCESS_UPDATED_MESSAGE": "La password è stata aggiornata con successo!",
    "EMAIL_SUCCESS_UPDATED_MESSAGE": "La tua email è stata aggiornata con successo! Controlla la tua casella di posta per verificare il tuo indirizzo",
    "PREFERENCE_COOKIE_LABEL": "Preferenze Cookie",
    "COOKIE_POLICY_LABEL": "Cookie Policy",
    "PRIVACY_POLICY_LABEL": "Privacy Policy",
    "CONFIRM_PASSWORD": "Conferma password",
    "CONFIRM_PASSWORD_REQUIRED":"Conferma password è un campo obbligatorio",
    "NEW_PASSWORD":"Nuova password",
    "CONFIRM_NEW_PASSWORD":"Conferma nuova password",

    "DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_CONTENT": "Questo è un set di domande di sola lettura. Prima di poterlo compilare devi completare tutti i set precedenti.",
    "DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_BACK"   : "Indietro",
    "DIALOG_WARNING_UNFINISHED_SURVEY_CONTENT"         : "Continuando si perderanno le modifiche inserite. Premere Indietro per salvarle, Ok per continuare.",
    "DIALOG_WARNING_UNFINISHED_SURVEY_BACK"            : "Indietro",

    "REPORT_INCOMPLETED_YEAR_MESSAGE": "Il questionario non è stato completato per l'annata ",
    "SURVEY_PLUS_OLDER_THAN_2021": "Non è possibile compilare il questionario Plus per annate antecedenti al 2021.",

    "MISSING_YEARLY_PARAMETERS": "Inserire le informazioni dell'azienda per l'anno ",

    "SURVEY_TEXTAREA_LABEL": "(DESCRIZIONE)",

    "HEADER_USER_GUIDE": "Guida utente",

    "CONFIRM_MODAL_BUTTON_MESSAGE_CLOSE": "Torna a Modificare",
    "CONFIRM_MODAL_BUTTON_MESSAGE_CONTINUE": "Salva e Continua",

    "CHANGE_COMPANY_TYPE_ALERT_MESSAGE": "Sei sicuro di voler cambiare la tipologia azienda? La modifica implica la cancellazione del questionario Inclusion Plus per l’annata selezionata. Sarà necessario re-inserirlo.",

    "SMALL_SCREEN_WARNING_MESSAGE": "Per un utilizzo ottimale dello strumento si raccomanda di utilizzare uno schermo con risoluzione maggiore come un tablet o un computer.",

    "ADMIN_TABLE_UPDATE_USER_SUCCESS_MESSAGE": "L'utente è stato aggiornato con successo!",
    "ADMIN_TABLE_UPDATE_COMPANY_SUCCESS_MESSAGE": "L'azienda è stata aggiornata con successo!",

    "EDIT_ACCOUNT_SETTINGS_MODAL_TITLE": "Modifica Impostazioni Account",
    "EDIT_COMPANY_SETTINGS_MODAL_TITLE": "Modifica Impostazioni Azienda",

    "EDIT_PASSWORD_MODAL_NEW_PASSWORD_LABEL": "Nuova Password",
    "EDIT_PASSWORD_MODAL_NEW_PASSWORD_CONFIRMATION_LABEL": "Conferma Nuova Password",

    "VIEW_LABEL_ADMIN_PANEL": "Vedi",

    "ADMIN_PANEL_TO_APPROVE_USERS": "ACCOUNT DA APPROVARE",
    "ADMIN_PANEL_APPROVED_USERS": "ACCOUNT APPROVATI",

    "EXPORT_RESPONSE_SUMMARY_BUTTON_LABEL": "Esporta riepilogo risposte",
    "EXPORT_ACCOUNTS_BUTTON_LABEL": "Esporta anagrafiche",

    "ADMIN_PANEL_ACTIONS_DROPDOWN_LABEL": "Azioni",

    "LANGUAGE_SELECTED": "Lingua Italiana selezionata",
    "ADMIN_PANEL_LABEL": "Pannello admin",
    "FACE_OF_A_PERSON": "Volto di una persona",
    "TEST_ICON_LABEL": "Test",
    "SCORE_ICON_LABEL": "Punteggio",
    "INFO_REPORT_ICON_LABEL": "Informazioni Report",

    "DASHBOARD_WIDGET_TITLE_1": "La tua azienda non fa ancora parte di Valore D?",
    "DASHBOARD_WIDGET_TITLE_2": "È il momento perfetto per scoprire di più sul nostro network!",
    "DASHBOARD_WIDGET_SUBTITLE": "Scrivici per approfondire le attività rivolte alle aziende associate e le modalità di adesione.",
    "DASHBOARD_WIDGET_BUTTON_CONTACT_US": "Contattaci",
};

export default it_messages;
